

import React, {useState,useEffect} from 'react';
import { useFormik } from 'formik';
import { Link, NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import './styles.css'
import loginBanner from '../../assets/images/login-banner.png';
import { API_URL } from '../../redux-services/api/api.js';
import { API } from '../../utils/networkApi.js';
import toaster from "../../utils/ReactTostify";

const optionsRdBtn = ["Male", "Female"];
function Register(props) {
  
  const  state = {
        divcontainer:false,
    }
    const [otp,setOtp]=useState(state);
    const [otpvalue,setOtpvalue]=useState('');
    const[verifyToken,setVerifyToken]=useState('');
    console.log('otpvalue', verifyToken);


  
    console.log('otp value is ', otp);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [reqId, setReqId] = useState('');
    console.log('setReqId', reqId);



    const [seconds, setSeconds] = useState(60);
    const [sendOTPhideShow, setSendOTPhideShow] = useState(false);
    const [sendOTPDisable, setSendOTPDisable] = useState(false);
    const [signUpDisable, setSignUpDisable] = useState(true);
    const [phoneNumberError, setPhoneNumberError] = useState(null);

    const [resendOTPhideShow, setResendOTPhideShow] = useState(true);
    const dateValue = moment(new Date()).format("YYYY-MM-DD");
    const [dateOfBirth, setDateOfBirth] = useState(dateValue);

    // const mobileTest = new RegExp(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/);
// const mobileTest = new RegExp(/^[6-9]\d{9}$/gi);
const mobileTest = new RegExp(/^[6-9]{1}[0-9]{9}$/);
const classes = useStyles();




useEffect(() => {
  // Load MSG91 OTP provider script dynamically
  const script = document.createElement('script');
  script.src = 'https://control.msg91.com/app/assets/otp-provider/otp-provider.js';
  script.async = true;
  script.onload = () => {
    // Check if initSendOTP is available
    if (window.initSendOTP) {
      window.initSendOTP(window.configuration); // Initialize the OTP widget
    }
  };
  document.body.appendChild(script);

  // Define OTP widget configuration
  window.configuration = {
    widgetId: '346b41693757373535313137',
    tokenAuth: '277152TgzHxhl6g6746edefP1', // Replace with your MSG91 token
    exposeMethods: true, // Exposes sendOtp, retryOtp, and verifyOtp functions
    success: (data) => {
      console.log('OTP Success:', data);
    },
    failure: (error) => {
      console.log('OTP Error:', error);
    },
  };

  return () => {
    document.body.removeChild(script); // Clean up the script on component unmount
  };
}, []);

    useEffect(() => {
      setSendOTPhideShow(false);
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
        // setSendOTPhideShow(true);
        setResendOTPhideShow(true);
        // setSignUpDisable(true);
      } else {
        setSeconds(0); 
        setSendOTPhideShow(true);
        setResendOTPhideShow(false);
        setSignUpDisable(false);
    
      }
    });


    const sendOtp = () => {
      console.log("window.sendOtp:", window.sendOtp);
      console.log("Phone Number:", phoneNumber);
    
      if (window.sendOtp && phoneNumber) {
        let mobile = "91" + phoneNumber;
        console.log("Sending OTP to:", mobile);
    
        window.sendOtp(
          mobile,
          (data) => {
            console.log("OTP sent successfully:", data.message);
            setReqId( data.message);
            console.log("OTP sent successfully setReqId", reqId);

          },
          (error) => console.error("Error sending OTP:", error)
        );
      } else {
        console.warn("Send OTP function not available or phone number is empty");
      }
    };
    

    const retryOtp = (channel = null) => {
      console.log("Retrying OTP...");
      console.log("Current reqId:", reqId);
    
      if (window.retryOtp && reqId) {
        let retryChannel = channel || "SMS"; // Default to "SMS" if no channel is provided
        console.log("Retrying OTP with channel:", retryChannel);
        
        window.retryOtp(
          11,
          (data) => {
            console.log("OTP retry successful:", data);
            toaster("success", "OTP has been resent successfully.");
          },
          (error) => {
            console.error("Error during OTP retry:", error);
            toaster("error", "Failed to resend OTP. Please try again.");
          },
          reqId // Pass the Request ID
        );
      } else {
        console.warn("Retry OTP function not available or reqId is missing");
        if (!window.retryOtp) {
          toaster("error", "Retry OTP function is not defined.");
        }
        if (!reqId) {
          toaster("error", "Request ID is missing.");
        }
      }
    };
  // const retryOtp = (channel = null) => {
  //   console.log('OTP retried hi');
  //   if (window.retryOtp && reqId) {
      
  //     window.retryOtp(
  //       channel,
  //       (data) => {
  //         console.log('OTP retried:', data);
  //       },
  //       (error) => console.error('Error retrying OTP:', error),
  //       reqId
  //     );
  //   } else {
  //     console.warn('Retry OTP function not available or reqId is missing');
  //   }
  // };

  // Function to verify OTP
  // const verifyOtp = () => {
  //   if (window.verifyOtp && otp && reqId) {
  //     let OtpNumber = otp;
  //     console.error('OTP verified:', otp);
  //     window.verifyOtp(
  //       OtpNumber,
  //       (data) => {
  //         console.log('OTP verified:', data);
  //         toaster('success', "otp verified succefully");
  //       },
      
  //       (error) => console.error('Error verifying OTP:', error),
  //       reqId
  //     );
  //   } else {
  //     console.warn('Verify OTP function not available, OTP or reqId is missing');
  //   }

  // };





    	// history path
	const nextPath = (path) =>{
		props.history.push(path);
	  }




 

    // const validate = values => {
    //   const errors = {}
    //   if (!values.phone) {
    //     errors.phone = 'Phone number must not be empty'
    //     } else if (!mobileTest.test(values.phone)) {
    //       // errors.phone = "Invalid phone number format";
    //       errors.phone = "Invalid phone number";
    //       }else if ((values.phone.length < 10) || (values.phone.length > 10)) {
    //       errors.phone = 'Phone number must be 10 digits';
    //       }

    //     if (!values.otp) {
    //       errors.otp = 'OTP must not be empty'
    //       } else if ((values.otp.length < 4) || (values.otp.length > 4)) {
    //       errors.otp = 'OTP must be 4 characters'
    //       } 

    //       if (!values.mpin) {
    //         errors.mpin = 'Mpin must not be empty'
    //         } else if ((values.mpin.length < 4) || (values.mpin.length > 4)) {
    //         errors.mpin = 'Mpin must be 4 characters'
    //         } 

    //         if (!values.firstname) {
    //           errors.firstname = 'First Name must not be empty'
    //           } else if ((values.firstname.length < 1)) {
    //           errors.firstname = 'Please enter atleast 1 character'
    //           }

    //           if (!values.lastname) {
    //             errors.lastname = 'Last Name must not be empty'
    //             } else if ((values.lastname.length < 1)) {
    //             errors.lastname = 'Please enter atleast 1 character'
    //             }

    //             if (!values.gender) {
    //               errors.gender = 'Gender must not be empty'
    //               }
    //               if(!values.dateofbirth) {
    //                 errors.dateofbirth = Yup.string().required("Date of birth is required")
    //     .test("is-greater", "Date of birth should not be greater than present date", function(value) {
    //       return moment(value,"YYYY-MM-DD").isSameOrBefore(moment().format("YYYY-MM-DD"));
    //     })
    //               }
    //     return errors
    //   }


    const validate =      Yup.object({
      otp: Yup.string()
      .min(6,'OTP must be 4 characters')
      .max(6,'OTP must be 4 characters')
      .required('OTP must not be empty'),
      mpin: Yup.string()
      .min(4,'MPIN must be 4 characters')
      .max(4,'MPIN must be 4 characters')
      .matches(/^[0-9]{4}$/, 'MPIN must be 4 numeric characters')
      .required('MPIN must not be empty'),

      firstname: Yup.string()
      .min(1, 'Please enter atleast 1 character')
      .max(20,'Must be 20 character or less')
      .required('First name must not be empty'),
      lastname: Yup.string()
      .min(1, 'Please enter atleast 1 character')
      .max(20,'Must be 20 character or less')
      .required('Last name must not be empty'),

      dateofbirth: Yup.string().required("Date of birth is required")
      .test("is-greater", "Date of birth should not be greater than present date", function(value) {
        return moment(value,"YYYY-MM-DD").isSameOrBefore(moment().format("YYYY-MM-DD"));
      }),
      phone: Yup.string().matches(mobileTest, 'phone number is not valid')
          .min(10, 'Phone number should include 10 character')
          .max(10, 'Phone number allowed only 10 character')
          .required('Phone number required'),
          

      // .required('Email address is required'),   
      
      
      gender: Yup.string().required('Gender must not be empty'),
  
      
  
  })


    const formik = useFormik({

			initialValues: {
        "phone":"",
        "otp":"",
        "firstname":"",
        "lastname":"",
        "mpin":"",
        "gender":"",
        "dateofbirth":"",
        // "accessToken":verifyToken
			},
      validationSchema: validate,
			onSubmit: values => {
        console.log("data register submitted",values);
        verifyOtp();
			//   alert(JSON.stringify(values, null, 2))
			const data1 = {
        "phone":values.phone,
        "otp":values.otp,
        "firstname":values.firstname,
        "lastname":values.lastname,
        "mpin":values.mpin,
        "gender":values.gender,
        "date_of_birth":values.dateofbirth,
        // "accessToken":values.verifyToken
			}
			console.log("data register submitted",data1);
  

      verifyOtp(data1);
			}
		
		  })
		
      const verifyOtp = async (data1) => {
        console.log("Verifying OTP...",data1);
      
        if (window.verifyOtp && otpvalue && reqId) {
          let OtpNumber = otpvalue; // Use the entered OTP
          console.log("Sending OTP for verification:", OtpNumber);
          console.log("Verifying OTP...",data1);
          return new Promise((resolve, reject) => {
            window.verifyOtp(
              OtpNumber,
              (data) => {
                console.log("OTP verified successfully:1111", data1);
                toaster("success", "OTP verified successfully");
                handleSignup(data1,data.message)
                
                setVerifyToken(data.message); // Set verifyToken
                resolve(data); // Resolve promise after setting token
              },
              (error) => {
                console.error("Error verifying OTP:", error);
                reject(error); // Reject promise on error
              },
              reqId
            );
          });
        } else {
          console.warn("Verify OTP function not available, OTP or reqId is missing");
        }
      };
      
      const handleSignup = async (data, verifyToken) => {
        try {
          // Log the incoming data and verifyToken
          console.log("Incoming data for signup:", data);
          console.log("Incoming verifyToken:", verifyToken);
      
          // Combine data with accessToken
          const datavalue = {
            ...data, // Spread existing data
            accessToken: verifyToken, // Add the accessToken
          };
      
          // Log the final data being sent to the API
          console.log("Final data being sent to API:", datavalue);
      
          // Make the API call
          const result = await API.post(API_URL.VERIFY_OTP, datavalue);
          const response = result.data;
      
          // Log and handle the API response
          console.log("API response for signup:", response);
      
          if (response.status === 200) {
            toaster("success", response.message);
            nextPath("/home/login");
          }
        } catch (error) {
          console.error("Error during signup API call:", error);
          toaster("error", error.message || "An error occurred during signup.");
        }
      };
      






      const handleSendOTP = async() =>{
        try{
          if(phoneNumberError === null){
            if(phoneNumber === ""){
              setPhoneNumberError('Phone number must not be empty');
            }
             else{
              setSendOTPDisable(true);
              setSignUpDisable(false);
              const data ={
                "phone": `${phoneNumber}`
              }
              const result = await API.post(API_URL.SEND_OTP,data);
              let response = await result.data;
              console.log("response values of the send otp",response);
              if(response.status === 200){
                sendOtp();
                toaster('success', "otp is send successfully");
                setOtp({divcontainer:!otp.divcontainer});
              }
            }
  
           
          }
          
          
        }
        catch( error){
          setSendOTPDisable(false);
        }
    
      }


    const handleResendOTP = async() =>{
      


       try{
        setSeconds(5);
        setSendOTPhideShow(true);
        setSendOTPDisable(true);
        setSignUpDisable(false);

        const data ={
          "phone": `${phoneNumber}`
        }
        const result = await API.post(API_URL.RESEND_OTP,data);
        let response = await result.data;
        console.log("response values of the resend otp",response);
        if(response.status === 200){
          toaster('success', response.message);
        
        }
        
      }
      catch( error){
        toaster('error', error);
      }
    }

    const handleDateOfBirth = (event) =>{
      //  const Dateset = moment(data).format("YYYY-MM-DD");
       console.log("date of birth == " ,event.target.value);
      setDateOfBirth(event.target.value);
      formik.setFieldValue("dateofbirth",event.target.value);
    }

















   {/*const [counter,setCounter]=useState(180);

//Timer

    useEffect(()=>{
const timer = 
    
    (counter > 0) && setInterval(()=>setCounter(counter,-1),1000);
return()=>clearInterval(timer);
 },[counter]);*/}
    {/*const [timer1, setTimer1] = useState(false);
  const [time, setTime] = useState({});
  const [seconds, setSeconds] = useState(60);
const[loading,setLoading] = useState(false);
   useEffect(()=>{
    document.body.classList.add('account-page');
    return ()=>{
        document.body.classList.remove('account-page');
    }
   }, []) 
   let timer=0;
        
   const countDown = async() =>{
    // Remove one second, set state so a re-render happens.
    let sec = seconds - 1;
     setTime(secondsToTime(sec));
     setSeconds(sec);
     
    //  console.warn(" timer", sec);
    //  console.warn(" timer second", seconds);

    // Check if we're at zero.
    if (sec == 0) {
      clearInterval(timer);
      setTimer1(false);
    }
  }
  const secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));
    
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }
  useEffect(() => {
    if(timer1){

      let timeLeftVar = secondsToTime(seconds);
      setTime(timeLeftVar);
      if(timer == 0 && seconds > 0) {
        timer = setInterval(() =>{
          countDown()},1000);      
      }

    }

    
     return () => {    
      clearInterval(timer);
      // setData(initialData);
      setLoading(false);
      //on_change_text();
    }
  },[timer1,seconds
    , loading
  ]);
*/}
   
    
        // var HandleSendOTP = e =>
        // {
        //     setOtp({divcontainer:!otp.divcontainer});
        // }
        const x=otp.divcontainer;

        const handleRadioOptn = (event) =>{
          // setPhoneNumber(event.target.value);
          // console.log("data ",event.target.value);
          formik.setFieldValue("gender",event.target.value);
      }
      
      const onBlurPhone = (event) =>{
        
       
      if (!event.target.value) {
        setPhoneNumberError('Phone number must not be empty');
        } else if ((event.target.value.length < 10) || (event.target.value.length > 10)  ) {
          setPhoneNumberError('Phone number must be 10 digits')
        } else if (!mobileTest.test(event.target.value)) {
          // errors.phone = "Invalid phone number format";
          setPhoneNumberError("Invalid phone number");
          } 

        else{
          setPhoneNumberError(null);
        setPhoneNumber(event.target.value);

        formik.setFieldValue("phone",event.target.value);
        }
      }
        return(
            <div className="content">
            <div className="container-fluid">
                
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                            
                  
                        <div className="account-content">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-7 col-lg-6 login-left">
                                <img src={loginBanner} className="img-fluid" alt="Doccure Register" />	
                                </div>
                                <div className="col-md-12 col-lg-6 login-right">
                                    <div className="login-header">
                                        <h3>User Register </h3>
                                        
                                    </div>
                                    
                                  
                                    <form onSubmit={formik.handleSubmit}>
                                   {(sendOTPhideShow == false)?(<h6 className="text-muted">Enter your mobile number and click on SEND OTP for OTP</h6>):null} 
                                        <div>
                                        <div className="form-group">
                                            <input type="text" className="form-control " id="mobile"
                                            placeholder="Mobile Number"
                                              // value={phoneNumber}
                // onChange={(event) => onChangePhone(event)}
                maxLength={10}
                 onBlur={(event) =>onBlurPhone(event)}
                                            />
                                            {/* <label className="focus-label  " htmlFor="mobile" >
                                                
                                                 Mobile Number 
                                                </label> */}
                                            </div>
                                            {(phoneNumberError !== null)? (<div className='error mb-2 mt-0'>{phoneNumberError}</div>):(formik.touched.phone && formik.errors.phone) ? (<div className='error'>{formik.errors.phone}</div>) : null}
                                           </div>
                                           {/*} <div className="col-4">*/}

                                            {/*</div>*/}
                                            <button 
                                            // className="btn-primary btn-block btn-sm login-btn" 
                                            className={(sendOTPDisable == true) ? "btn-secondary btn-block btn-sm login-btn":"btn-primary btn-block btn-sm login-btn"} 
                                            type="button" 
                                            onClick={handleSendOTP} 
                                            // disabled={sendOTPhideShow}
                                            disabled={sendOTPDisable}
                                            > {x?'Send OTP':'Send OTP'}</button>  
                                           
                                       {     
                                       
                                       x&&(

                                        <>
                                       {/*} <span>00:{counter}</span>*/}

                                      <div className="resendotp"><strong>Resend OTP in</strong> <span style={{color:"green",fontWeight:"bold",align:"center"}}> 00:{seconds}</span></div>

                                      {(resendOTPhideShow == false)?(<h6 className="text-muted">Click on RESEND OTP for OTP to be sent again</h6>):null} 

                                      <button 
                                      // className="btn-primary btn-block btn-sm login-btn mb-3"
                                      className={(resendOTPhideShow == true)?"btn-secondary btn-block btn-sm login-btn mb-3":"btn-primary btn-block btn-sm login-btn mb-3"} 
                                      type="button" 
                                      onClick={() => retryOtp()} 
                                      disabled={resendOTPhideShow}> {'Resend OTP'}</button> 
  

                        
                                      {/* <div>
                                        <div className="form-group">
                                        <input type="text" 
                                        className="form-control " 
                                        id="otp"
                                        maxLength={6}
                                        onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.otp}
                                                    placeholder="Enter OTP"
                                         />
                                        // {/* <label className="focus-label" htmlFor="otp">Enter OTP</label> */}
                                    {/* </div>
                                    {formik.touched.otp && formik.errors.otp ? <div className='error'>{formik.errors.otp}</div> : null}
                                    </div> */} 

<div>
  <div className="form-group">
    <input
      type="text"
      className="form-control"
      id="otp"
      maxLength={6}
      onChange={(e) => {
        formik.handleChange(e);  // Let Formik handle the state change
        setOtpvalue(e.target.value); // Manually update state with OTP value
      }}
      onBlur={formik.handleBlur}
      value={formik.values.otp}
      placeholder="Enter OTP"
    />
    {/* <label className="focus-label" htmlFor="otp">Enter OTP</label> */}
  </div>
  {formik.touched.otp && formik.errors.otp ? (
    <div className="error">{formik.errors.otp}</div>
  ) : null}
</div>

                                    
                                    <div>
                                    <div className="form-group">
                                    
                                            <input type="text"
                                            className="form-control "  
                                            id="firstname"
                                            onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.firstname} 
                                                    placeholder="First Name"

                                            />
                                         {/* <label className="focus-label" htmlFor="firstname">First Name   </label> */}
                                        </div>
                                        {formik.touched.firstname && formik.errors.firstname ? <div className='error'>{formik.errors.firstname}</div> : null}
                                        </div>
                                        <div>

                                        

                                        <div className="form-group">
                                            <input type="text" 
                                            className="form-control  " 
                                            id="lastname" 
                                            onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.lastname}
                                                    placeholder="Last Name"
                                            />
                                            {/* <label className="focus-label" htmlFor="lastname">Last Name</label> */}
                                        </div>
                                        {formik.touched.lastname && formik.errors.lastname ? <div className='error'>{formik.errors.lastname}</div> : null}
                                        </div>

                                        
                                        <div className="">
                <div className="form-group">
                    <label>Date of Birth</label>
             
                    <div className="">
                                {/* <input type="text" className="form-control datetimepicker" defaultValue="24-07-198" */}
                                {/* <DatePicker
                                  className="form-control datetimepicker"
                                //   dateFormat="yyyy-mm-dd"
                                  selected={dateOfBirth}
                                  onChange={(date) => handleDateOfBirth(date)}
                                /> */}
                                <TextField
        id="date"
        type="date"
        defaultValue={dateOfBirth}
        className={classes.textField }
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(date) => handleDateOfBirth(date)}
      />
                              </div>
                              {
            formik.touched.dateofbirth && formik.errors.dateofbirth ? (
              <div className='error'
              >
                {formik.errors.dateofbirth}
              </div>
            ) : null}
                
               
                </div>
                </div>


                                        <div className="">
                <div className="form-group ">
                    <label>Gender</label>
                    <div className="">
                    
                    <div className="row ml-2">
                    {optionsRdBtn.map((options, idxo) => (
                  <div key={idxo} className="text-primary col-md-4" id={idxo} style={{ marginLeft: '10px' }}>
                      
                    
                      <input
                        className="form-check-input"            
                        type="radio"
                        id={`optnRd[${idxo}]`}
                        value={options}
                        name={`optnameVal`}
                        
                        onChange={(event) => {
                          handleRadioOptn(event);
                        }}
                      />
                      <label>
                      {options}
                    </label>
                  </div>

                ))}
                </div>
                {
            formik.touched.gender && formik.errors.gender ? (
              <div className='error'
              >
                {formik.errors.gender}
              </div>
            ) : null}
                    </div>
                </div>
                </div>
    
                                    
                                        <div>
                                    <div className="form-group">
                                        <input type="password" 
                                        className="form-control floating" 
                                        id="mpin"
                                        maxLength={4}
                                        onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.mpin}
                                                    placeholder="Enter MPIN" />
                                        {/* <label className="focus-label" htmlFor="mpin">Enter MPIN</label> */}
                                    </div>
                                    {formik.touched.mpin && formik.errors.mpin ? <div className='error'>{formik.errors.mpin}</div> : null}
                                    </div>
                                    
                                    </>

                                       )
                                        

                                        }
                                        
                                        <div className="text-right mt-3">
                                            <Link to="/home/login"className="forgot-link">Already have an account?</Link>
                                        </div>
                                        <button className="btn btn-primary btn-block btn-lg login-btn" 
                                        type="submit"
                                        disabled={signUpDisable}
                                        >Signup</button>
                            
                                    </form>
                                 
                                    
                                </div>
                            </div>
                        </div>
                        
                            
                    </div>
                </div>
        
            </div>
        
        </div>		
        );
        
    }
    const useStyles = makeStyles((theme) => ({
      container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
      },
    }));


export default Register;