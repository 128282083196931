import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from 'yup';
import moment from "moment";
import IMG01 from '../../../../assets/images/doctors/doctor-02.jpg';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { get_all_time_slot_req } from "../../../../redux-services/actions/action.order/action.function.js"
import './selectStyle.css'
import SelectAddress from "../../bookings/selectAddress/index";
import { createLogger } from 'redux-logger';
function UserBooking(props) {
	// const moment = require('moment')
	const current_time = moment().format("HH:mm:ss");
	const current_date = moment(new Date()).format("DD MMM YYYY");

	const dateValue = moment(new Date()).format("DD MMMM YYYY");

	const dayValue = moment(new Date()).format("dddd");
	// const newValue = moment(new Date()).add({days:7,months:1});
	// // const [newVal, setNewVal] = useState(dateValue);
	const [dateVal, setDateVal] = useState(dateValue);
	const [dayVal, setDayVal] = useState(dayValue);

	// values for day ittereation
	const [dayIntDisp, setDayIntDsip] = useState([]);
	const [dayInt, setDayInt] = useState([]);

	console.log("currentTime", current_time);

	const dayIterationFnc = () => {
		let newdayInt = [...dayInt];
		let newdayIntDisp = [...dayIntDisp];


		for (let i = 0; i < 7; i++) {
			newdayIntDisp[i] = moment().add(i, 'days').format('ddd ');
			newdayInt[i] = moment().add(i, 'days').format('  DD MMM YYYY');
		}
		setDayIntDsip(newdayIntDisp);
		setDayInt(newdayInt);
	}

	const [timeSlotData, setTimeSlotData] = useState(null);
	const [selectoptionsTimeSlot, setSelectoptionsTimeSlot] = useState([]);
	const getTimeSlotApiCall = async () => {

		await props.get_all_time_slot(null, (error, success) => {
			if (error) {

				console.log("error", error);
			}
			
			if (success.status == 200) {
				if (success && (success?.response.length > 0)) {
					setTimeSlotData(success?.response);
				}
			}

		});


	}

	useEffect(() => {
		dayIterationFnc();
		getTimeSlotApiCall();
	}, []);




	const [selectvalue, setSelectvalue] = useState([]);

	const settingValue = (timeslotid, dayValue, idVar, idx) => {
        
		let idPassed = document.getElementById(`${idVar}${idx}`); 

		console.log("id passed======", idPassed.id);
		

		let newSelectvalue = [...selectvalue];
	
		let bookingTime = new Array(newSelectvalue[`${idVar}${idx}`]).fill(false);
		if(idPassed.id == `${idVar}${idx}`){
			bookingTime[`${idVar}${idx}`] = true;	
		}
		setSelectvalue(bookingTime);
		//   setSelectValueColor("green");
		console.log("selected value======", selectvalue);
		console.log("value from time======", timeslotid);

		console.log("value from day======", dayValue);
		props.setTimeSlotDayUpdate(dayValue);
		props.setTimeSlotUpdate(timeslotid);

		let data = {
			"dayValue":dayValue,
			"timeslotid":timeslotid
		}

		props.onChangeValue(data);

	}


	console.log("id time slot=====", selectvalue);
	
	return (

		<div>
			{/* <div className="breadcrumb-bar"> */}
			<div className="container-fluid">
				<div className="row align-items-center">
					<div className="col-md-12 col-12">
						
					</div>
				</div>
			</div>
			{/* </div> */}
			{/* <SelectAddress{...props}/> */}

			<div className="content">
				<div className="container">

					<div className="row">
						<div className="col-12">
							<div className="row">
								<div className="col-12 col-sm-4 col-md-6">
									<h4 className="mb-1">{dateVal}</h4>
									<p className="text-muted">{dayVal}</p>
								</div>
								<div className="col-12 col-sm-8 col-md-6 text-sm-right">

									
								</div>
							</div>
						

							<div className="card booking-schedule schedule-widget">


								<div className="schedule-header">
									<div className="row">
										<div className="col-md-12">


											<div className="day-slot">

												<ul>
												
													
													{((dayIntDisp.length!= 0) && (dayInt.length!= 0))?(dayInt.map((item,idx) =>(
														
														<li key={idx}> 
														{/* {
															(timeSlotData != null) ?timeSlotData.map((itemData, idx) =>(
															<>
															{
																(itemData.is_order_before > itemData.start_time)? (
																	<>
																	{moment(`${item}`).subtract(1, 'days').format('  DD MMM YYYY')}
																	</>
																):null
															}
															</>
                                                          
														  )):null
														} */}
														
														<span>{dayIntDisp[idx]}</span>
														<span className="slot-date">{item}</span> 
													</li>
														
  )))
													:null	
													}
													
												</ul>
											</div>


										</div>
									</div>
								</div>

								<div className="schedule-cont">
									<div className="row">
										<div className="col-md-12">


											<div className="time-slot">

												<ul className="clearfix donate-now">

												{
															((dayInt.length != 0) && (timeSlotData != null)) ? dayInt.map((itemDay, idVal) => (

																
																	<li key={idVal}>
																		{
																		(timeSlotData != null) ? timeSlotData.map((item, idx) => (
																			
																			<span 
																			 
																			//   className={((moment().format("X") > moment(`${itemDay} ${item.is_order_before}`).format("X"))?"timing elapsed":((selectvalue[`${idVal}${idx}`] == true)?"timing selected":"timing"))} 
																			className={((moment().format("X") > (((item.is_order_before > item.start_time)?moment(`${itemDay} ${item.is_order_before}`).subtract(1,'days').format("X") : moment(`${itemDay} ${item.is_order_before}`).format("X"))))?"timing elapsed":((selectvalue[`${idVal}${idx}`] == true)?"timing selected":"timing"))} 
																			id={`${idVal}${idx}`}
																			 disabled={(moment().format("X") > (((item.is_order_before > item.start_time)?moment(`${itemDay} ${item.is_order_before}`).subtract(1,'days').format("X") : moment(`${itemDay} ${item.is_order_before}`).format("X")))) ? true : false}
																			
																			//  onClick={() => ((moment().format("X") > moment(`${itemDay} ${item.is_order_before}`).format("X")) ? null:settingValue(item.timeslotid,moment(`${itemDay} ${item.start_time}`).format("X"),idVal,idx))}
																			 onClick={() => ((moment().format("X") > (((item.is_order_before > item.start_time)?moment(`${itemDay} ${item.is_order_before}`).subtract(1,'days').format("X") : moment(`${itemDay} ${item.is_order_before}`).format("X")))) ? null:settingValue(item.timeslotid,moment(`${itemDay} ${item.start_time}`).format("X"),idVal,idx))}
																			// onClick={() =>settingValue(item.timeslotid,moment(`${itemDay} ${item.start_time}`).format("X"),idVal,idx)}
																			key={idx} >
																				<label 
																				//  for={moment(`${itemDay} ${item.start_time}`).format("X")} 
																				>
																					

																				{(moment(`${itemDay} ${item.start_time}`).format("HH:mm"))}
																					
																				{/* {`${idVal}${idx}`} */}
																					{/* <input type="radio"
																						id={moment(`${itemDay} ${item.start_time}`).format("X")}
																						// className="my-auto"
																						value={item.start_time}
																						name={`optTime`}
																						disabled={(moment().format("X") > moment(`${itemDay} ${item.is_order_before}`).format("X")) ? "disabled" : null}
																						
																					/> */}
																				

																				{/* <span>{(moment(`${itemDay} ${item.start_time}`).format("HH:mm"))}</span> */}
																				
																					
																					
																					

																				</label>	
																				
																			</span>

																		)) : null}
																	</li>
																
															)) : null

														}

												</ul>
											</div>
											

										</div>
									</div>
								</div>


							</div>
							<div>

							</div>
							


						</div>
					</div>
				</div>

			</div>
		</div>
	);



}
const mapDispatchToProps = (dispatch) => {
	return {
		get_all_time_slot: (data, callback) => dispatch(get_all_time_slot_req(data, callback)),

	};
};
const mapStateToProps = (state) => {
	return {
		status: state.user.status,
		error: state.user.error,
		//   all_family_member: state.familymember.all_family_member,
		//   all_my_address: state.myaddress.all_my_address,
		//   create_order: state.order.create_order,
		all_time_slot: state.order.all_time_slot,
		//   all_coupons:state.order.all_coupons,
		user_by_id: state.user.user_by_id,
		//   cart:state.cart.cartVal,
		user: state.user.user,
		isLoggedIn: state.user.isLoggedIn,
		requesting: state.user.requesting
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBooking);
