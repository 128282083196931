import React from 'react';
import './HealthConcerns.css';

const HealthConcerns = () => {
  return (
    <div className="health-concerns-container">
      <h1 className="health-concerns-title">Health Concerns</h1>
      <div className="health-concerns-grid">
        <div className="health-concern-item">
          <i className="fas fa-thermometer-half health-concern-icon"></i>
          <p className="health-concern-label">FEVER</p>
        </div>
        <div className="health-concern-item">
          <i className="fas fa-tint health-concern-icon"></i>
          <p className="health-concern-label">DIABETES</p>
        </div>
        <div className="health-concern-item">
        <i className="fas fa-kidneys health-concern-icon"></i>
          <p className="health-concern-label">KIDNEYS</p>
        </div>
        <div className="health-concern-item">
          <i className="fas fa-liver health-concern-icon"></i>
          <p className="health-concern-label">LIVER</p>
        </div>
        <div className="health-concern-item">
          <i className="fas fa-thyroid-gland health-concern-icon"></i>
          <p className="health-concern-label">THYROID</p>
        </div>
        <div className="health-concern-item">
          <i className="fas fa-heartbeat health-concern-icon"></i>
          <p className="health-concern-label">HEART CHECK</p>
        </div>
        <div className="health-concern-item">
          <i className="fas fa-capsules health-concern-icon"></i>
          <p className="health-concern-label">VITAMIN PROFILE</p>
        </div>
        <div className="health-concern-item">
          <i className="fas fa-allergies health-concern-icon"></i>
          <p className="health-concern-label">ALLERGY</p>
        </div>
        <div className="health-concern-item">
          <i className="fas fa-baby health-concern-icon"></i>
          <p className="health-concern-label">INFERTILITY</p>
        </div>
        <div className="health-concern-item">
          <i className="fas fa-cancer-ribbon health-concern-icon"></i>
          <p className="health-concern-label">CANCER SCREENING</p>
        </div>
      </div>
      <button className="view-all-btn">View All</button>
    </div>
  );
};

export default HealthConcerns;