import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import moment from "moment";
import parse from 'html-react-parser';
import { useHistory } from "react-router";
//images
// import { IMG01, IMG02, IMG03, IMG04, IMG05, IMG06, IMG_th01, IMG_th02, IMG_th03, IMG_th04, IMG_th05, IMG_th06} from './img.jsx';
import IMG02 from "../../../../assets/images/placeholderImg.png"
import "./articlerelease.css"

//components
import ArticleListSearch from '../search/search.jsx';
import LastestArticle from '../lastestarticle';
import CategoryWidget from '../categoryWidget';
//import TagsWidget from '../tagswidget';
import { get_all_articles_req } from "../../../../redux-services/actions/action.article/action.function.js";
import { API_URL_ROOT } from '../../../../redux-services/actions/constance/action.constance';
function ArticleView(props) {
    const [articleData, setArticleData] = useState([]);
    const [noArticleData, setNoArticleData] = useState(null);


    const nextPathDataPush = (path, data) => {
        props.history.push({
            pathname: path, state: {
                data: data,
            }
        })
    }

    const getArticleApiCall = async () => {
        await props.get_all_articles(null, (error, success) => {
            if (error) {

                console.log("error", error);
            }
            if (success.status == 200) {
                if (success && success?.response.length > 0) {
                    setArticleData(success?.response);
                }
                if (success && success?.response.length <= 0) {
                    console.log("data length empty", success?.message);
                    setNoArticleData(success?.message);
                }
            }
        });


    }

    useEffect(() => {

        getArticleApiCall();

    }, []);
    const onClickArticle = (articleid) => {
        var data = {

            "articleid": articleid

        }

        nextPathDataPush(`/home/articles/articledetails`, data);

    }
    console.log("all article values=====", articleData);
    return (
        <div>
            {/* <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/home">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Articles</li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">Articles </h2>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            {/* <div className="row blog-grid-row">
								<div className="col-md-6 col-sm-12"> */}

                            {/* article api call  */}
                            {/* <div className="row ">
								<div className="col-md-6"> */}
                            <div className="row blog-grid-row">
                                {(articleData.length != 0) ? articleData.map((data, idx) => (
                                    <div key={idx} className="col-md-6 col-sm-12">
                                        <div className="blog grid-blog" onClick={() => onClickArticle(data.article_id)} style={{
                                            cursor: "pointer",
                                        }}>
                                            <div className="blog-image" >

                                                <span>
                                                    <img className="img-fluid"
                                                        style={{ maxHeight: '260px', minHeight: '260px' }}
                                                        // src={IMG02}  
                                                        src={(data.featured_image === null || data.featured_image === "null") ? IMG02 : `${API_URL_ROOT}/${data.featured_image}`}
                                                        alt="Post" />
                                                </span>
                                            </div>
                                            <div className="blog-content">
                                                <h3 className="blog-title" style={{
                                                    minHeight: "5px",
                                                }}>
                                                    <span onClick={() => onClickArticle(data.article_id)} style={{
                                                        cursor: "pointer",
                                                    }}>
                                                        {/* {(data.title != null)?data.title:null} */}
                                                        {(data.title != null) ? ((data.title.length > 40) ? `${data.title.substring(0, 40)}...` : data.title) : null}
                                                    </span>
                                                </h3>
                                                {/* <>
                                                {(data.document_blog!= null)?parse(data.document_blog):null}
                                                </> */}
                                                <> <p>   {(data.short_description != null) ? ((data.short_description.length > 30) ? `${data.short_description.substring(0, 30)}...` : data.short_description) : ""}</p>
                                                  
                                                </>
                                                <p className="article-release">{(data.created_at != null) ? moment(data.created_at).format("DD MMM YYYY") : null}</p>
                                            </div>




                                        </div>
                                    </div>
                                )) : (

                                    <div>
                                        {(noArticleData != null) ? noArticleData : null}
                                    </div>

                                )}

                            </div>
                            {/* </div> */}
                            {/* </div> */}


                            {/* dummy data  */}
                            {/* <div className="row blog-grid-row">
								<div className="col-md-6 col-sm-12">
                                <div className="blog grid-blog">
                                        <div className="blog-image">
                                            <Link to="/home/articles/articledetails"><img className="img-fluid" src={IMG02}  alt="Post" /></Link>
                                        </div>
                                        <div className="blog-content">
                                            
                                            <h3 className="blog-title">
                                            <Link to="/home/articles/articledetails">Padmashree Diagnostics – Making your clinic painless visit?</Link></h3>
                                           
                                            <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                <div className="blog grid-blog">
                                        <div className="blog-image">
                                            <Link to="/home/articles/articledetails"><img className="img-fluid" src={IMG02} alt="Post" /></Link>
                                        </div>
                                        <div className="blog-content">
                                         
                                            <h3 className="blog-title">
                                                <Link to="/home/articles/articledetails">What are the benefits of Online Doctor Booking?</Link></h3>
                                            <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                <div className="blog grid-blog">
                                    <div className="blog-image">
                                        <Link to="/home/articles/articledetails"><img className="img-fluid" src={IMG03} alt="Post" /></Link>
                                    </div>
                                    <div className="blog-content">
                                     
                                        <h3 className="blog-title"><Link to="/home/articles/articledetails">Benefits of consulting with an Online Doctor</Link></h3>
                                        <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                    </div>
                                </div>
                                 </div>
                                 <div className="col-md-6 col-sm-12">
                                 <div className="blog grid-blog">
                                        <div className="blog-image">
                                            <Link to="/home/articles/articledetails"><img className="img-fluid" src={IMG04} alt="Post" /></Link>
                                        </div>
                                        <div className="blog-content">
                                          
                                            <h3 className="blog-title"><Link to="/home/articles/articledetails">5 Great reasons to use an Online Doctor</Link></h3>
                                            <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="blog grid-blog">
                                            <div className="blog-image">
                                            <Link to="/home/articles/articledetails"><img className="img-fluid" src={IMG05} alt="Post" /></Link>
                                            </div>
                                            <div className="blog-content">
                                               
                                                <h3 className="blog-title"><Link to="/home/articles/articledetails">
                                                    Online Doctor Appointment Scheduling</Link></h3>
                                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                    <div className="blog grid-blog">
                                            <div className="blog-image">
                                            <Link to="/home/articles/articledetails"><img className="img-fluid" src={IMG06} alt="Post" /></Link>
                                            </div>
                                            <div className="blog-content">
                                                
                                                <h3 className="blog-title"><Link to="/home/articles/articledetails">Online Doctor Appointment Scheduling</Link></h3>
                                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                        </div>
                        <div className="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
                            {/* <ArticleListSearch /> */}
                            <LastestArticle {...props} />
                            {/* <CategoryWidget /> */}
                            {/*<TagsWidget />*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
const mapDispatchToProps = (dispatch) => {
    return {
        get_all_articles: (data, callback) =>
            dispatch(get_all_articles_req(data, callback)),

    };
};
const mapStateToProps = (state) => {
    return {
        status: state.user.status,
        error: state.user.error,
        all_family_member: state.familymember.all_family_member,
        all_my_address: state.myaddress.all_my_address,
        create_order: state.order.create_order,
        all_coupons: state.order.all_coupons,
        user_by_id: state.user.user_by_id,
        cart: state.cart.cartVal,
        total_cart_value: state.cart.total_cost,
        user: state.user.user,
        isLoggedIn: state.user.isLoggedIn,
        requesting: state.user.requesting
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleView);