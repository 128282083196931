import React, { useState } from 'react';
import './phone.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { byPrefixAndName } from '@awesome.me/kit-KIT_CODE/icons'
// import { fawhatsapp } from '@awesome.me/kit-KIT_CODE/icons/classic/solid'



const SocialIcons = () => {
  const [isPhoneExpanded1, setIsPhoneExpanded1] = useState(false);
  const [isWhatsAppExpanded1, setIsWhatsAppExpanded1] = useState(false);

  const handlePhoneClick = () => {
    setIsPhoneExpanded1(!isPhoneExpanded1);
    if (isWhatsAppExpanded1) setIsWhatsAppExpanded1(false);  // Close WhatsApp if Phone is clicked
  };

  const handleWhatsAppClick = () => {
    setIsWhatsAppExpanded1(!isWhatsAppExpanded1);
    if (isPhoneExpanded1) setIsPhoneExpanded1(false);  // Close Phone if WhatsApp is clicked
  };

  return (
    <div className="social-iconss">
      {/* Phone Button */}
      <button
        className={`expandable-button1 phone ${isPhoneExpanded1 ? 'expanded1' : ''}`}
        onClick={handlePhoneClick}
        aria-label="Contact Phone"
      >
        <span className="icon1">📞</span>
        <div className={`contact-text1 ${isPhoneExpanded1 ? 'expanded1' : ''}`}>
          <span>Download Report</span>
          <span>{isPhoneExpanded1 ? '+1 234 567 890' : ''}</span>
        </div>
      </button>

      {/* WhatsApp Button */}
      {/* <button
        className={`expandable-button whatsapp ${isWhatsAppExpanded ? 'expanded' : ''}`}
        onClick={handleWhatsAppClick}
        aria-label="Contact WhatsApp"
      >
        <span className="icon"><i className="fas fa-calendar-check"></i></span>
        <div className={`contact-text ${isWhatsAppExpanded ? 'expanded' : ''}`}>
          <span>Book On Call</span>
          <span>{isWhatsAppExpanded ? '+1 987 654 321' : ''}</span>
        </div>
      </button> */}
    </div>
  );
};

export default SocialIcons;

