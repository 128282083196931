import React from 'react';
import { TailSpin } from 'react-loader-spinner';

const Loader = ({ loading }) => {
  return (
    loading && (
      <div style={loaderStyle}>
        <TailSpin height="50" width="50" color="#4fa94d" ariaLabel="loading" />
      </div>
    )
  );
};

const loaderStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1000,
//   backgroundColor: 'rgba(255, 255, 255, 0.7)',
  padding: '1rem',
  borderRadius: '8px',
};

export default Loader;
