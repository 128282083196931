import React from "react"
import { connect } from "react-redux"
import { Redirect, Route } from "react-router"
import { addTokenToStateRequest } from "../redux-services/actions/action.users/action.function"

const CustomRoute = props => {
  const { isLoggedIn, protectedPath, checkedForToken,  path, currentUser, ...rest } = props
  //initial state
  // const [USERS, setUSERS] = React.useState({
  //   users : {
  //     role: "admin"
  //   }
  // })
  console.log('---------------------checkd', isLoggedIn, protectedPath, path, checkedForToken, currentUser,  props);
  // If not logged in and haven't checked for token yet,
  // try to query DB for user with token:
  if (!checkedForToken || !isLoggedIn) {
    //  props.addTokenToState()
    props.addTokenToStateRequest();
  }

  if (isLoggedIn && protectedPath && checkedForToken){
    if (currentUser.userId){
      return <Route
        {...rest}/>
    }
    else {
      return (<h1>You don't have access to this page</h1>)
    }
  }

  if (isLoggedIn || !protectedPath) {

    return <Route {...rest} />
  }

  if (protectedPath && !isLoggedIn) {
    return (
      <Redirect
        exact
        to="/user/dashboard"
      />
    )
  }

  return <Route {...rest} />
 }



const mapStateToProps = state => ({
  isLoggedIn: state.user.isLoggedIn,
  checkedForToken: state.user.checkedForToken,
  currentUser: state.user.user
})

// const mapDispatchToProps = { addTokenToState }
const mapDispatchToProps = { addTokenToStateRequest }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomRoute)
