import React, { useState, useEffect } from "react";
import {Link} from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import { Tabs, Tab,Tooltip, OverlayTrigger } from 'react-bootstrap';
// import { Modal} from 'react-bootstrap';
// import Icon from '@material-ui/core/Icon';

import { IMG01, IMG02, IMG03, IMG04, IMG05, IMG06, IMG07, IMG08 } from './img';

import img1 from "../../../../assets/images/features/feature-01.jpg";
import img2 from "../../../../assets/images/features/feature-02.jpg";
import img3 from "../../../../assets/images/features/feature-03.jpg";
import img4 from "../../../../assets/images/features/feature-04.jpg";
import { connect } from "react-redux";
import {useHistory} from "react-router";
import testImage from "../../../../assets/images/tests.png";

import {add_to_cart_request,delete_from_cart_request} from "../../../../redux-services/actions/action.cart/action.function.js"
const images = [img1, img2, img3, img4];
function TestDetails(props) {
	const [key,setKey]= useState(1);
	const [activeModal,setActiveModal]= useState(0);
		

	const handleSelect = (key) => {

		setKey(key)
	}

	const openModal= (id) => {
		setActiveModal(id);
	  
	}
	const handleCloseModal = () => {
		setActiveModal(false); 
	  };

	  const history = useHistory();
	  const nextPath = (path,data) => {
		history.push({
		  pathname: path,
		  state:data
		});
	  };

		


    const dataValue = history.location.state
	  console.log("data passed===",dataValue);
		return (

			<div>

				<div className="breadcrumb-bar">
					<div className="container-fluid">
						<div className="row align-items-center">
							<div className="col-md-12 col-12">
								<nav aria-label="breadcrumb" className="page-breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/home">Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page"><Link to="/home/allTestsView/allTests">Test details</Link></li>
									</ol>
								</nav>
								<h2 className="breadcrumb-title">Test Details</h2>
							</div>
						</div>
					</div>
				</div>

				<div className="content">
					


					<div className="card">
        <div className="card-body">
            <div className="doctor-widget">
                <div className="doc-info-left">
                    <div className="doctor-img">
                    <div>
                            <img src={testImage} className="img-fluid" alt="User" />
                        </div>
                    </div>
                    <div className="doc-info-cont">
                        <h4 className="doc-name">{dataValue.name}</h4>
                        <p className="doc-speciality">{dataValue.short_description}</p>
												
                     
                        <div className="clinic-details">
                            
                            <div>
   
                            {/* {isOpen && (
                                <Lightbox
                                enableZoom={false}
                                mainSrc={images[photoIndex]}
                                nextSrc={images[(photoIndex + 1) % images.length]}
                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                imageTitle={photoIndex + 1 + "/" + images.length}
                                
                                onCloseRequest={() => this.setState({ isOpen: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                    photoIndex: (photoIndex + images.length - 1) % images.length
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                    photoIndex: (photoIndex + 1) % images.length
                                    })
                                }
                                />
                             )} */}
                            </div> 
                            {/* <ul className="clinic-gallery">
                                <li>
                                    <a href="#0" onClick={() => this.setState({ isOpen: true, photoIndex: 0 })}>
                                        <img src={IMG03} alt="Feature" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#0" onClick={() => this.setState({ isOpen: true, photoIndex: 1 })}>
                                        <img  src={IMG04} alt="Feature" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#0" onClick={() => this.setState({ isOpen: true, photoIndex: 2 })}>
                                        <img src={IMG05} alt="Feature" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#0" onClick={() => this.setState({ isOpen: true, photoIndex: 3 })}>
                                        <img src={IMG06} alt="Feature" />
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                     
                    </div>
                </div>
                <div className="doc-info-right">
                    <div className="clini-infos">
                        <ul>
                           
                            <li><i className="far fa-money-bill-alt"></i> {(dataValue.cost != null)? `₹ ${dataValue.cost}`:"None"}
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{(dataValue.cost != null)? `₹ ${dataValue.cost}`:"None"}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                    <i className="fas fa-info-circle"></i>
                                                    </span>
                                            </OverlayTrigger></li>
                        </ul>
                    </div>
                    <div className="clinic-booking">
                   
                        {/* <Link to="/home" className="apt-btn">Add to Cart</Link> */}
                    </div>
                </div>
            </div>
        
    </div>
						<div className="card">
							<div className="card-body pt-0 user-tabs">
								<Tabs
									className="tab-view"
									activeKey={key}
									onSelect={handleSelect}
									id="controlled-tab-example"
								>
									<Tab className="nav-item" eventKey={1} title="Description">
										<div className="row">
											<div className="col-md-12 col-lg-9">


												<div className="widget about-widget">
													<h4 className="widget-title">About Test</h4>
													<p>{dataValue.details}</p>
												</div>
											</div>
										</div>
									</Tab>
									<Tab className="nav-item" eventKey={2} title="Prerequisits">
										<div>
											<div className="location-list">
												<div className="row">


													<div className="col-md-6">
													<div className="service-list">
													<h4>Prerequisits needed for this test</h4>
													<ul className="clearfix">
														<li>{dataValue.prerequisites}</li>
														
													</ul>
												</div>
														</div>
												</div>
											</div>

										</div>

									</Tab>
									
									
								</Tabs>

							</div>
						</div>

					</div>
				</div>
				
					
			</div>

		);

}


export default TestDetails;