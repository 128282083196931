import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./infra.css";


function Infrastructure(props) {
   
  


    const nextPathDataPush = (path, data) => {
        props.history.push({
            pathname: path,
            state: {
                data: data,
            },
        });
    };

    const onClickData = (imgPath, textData) => {
        var data = {
            textdata: textData,
            imagedata: imgPath,
        };

        nextPathDataPush(`/home/infrastructure/infrastructuredetails`, data);
        console.log("data", data);
    };
   


    return (
        <div>
            {/* <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/home">Home</Link>
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">Infrastructure</h2>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="row blog-grid-row">
                                {/* Repeat this block for each card */}
                                <div className="col-md-4 col-sm-12">
                                    <div className="blog grid-blog">
                                        <div
                                            className="image-section"
                                            style={{
                                                backgroundImage: `url(${require('../../../assets/images/doctors/infrastrucure_1.jpg')})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                height: "250px", // Adjust as needed
                                            }}
                                        ></div>
                                        <div className="blog-content">
                                            <span
                                                onClick={() => onClickData('infrastrucure_1.jpg', 'Biochemistry')}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <h3 className="text-muted" style={{ cursor: "pointer" }}>
                                                    Biochemistry
                                                </h3>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="blog grid-blog">
                                        <div
                                            className="image-section"
                                            style={{
                                                backgroundImage: `url(${require('../../../assets/images/doctors/infrastrucure_2.jpg')})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                height: "250px", // Adjust as needed
                                            }}
                                        ></div>
                                        <div className="blog-content">
                                            <span
                                                onClick={() => onClickData('infrastrucure_2.jpg', 'Haematology')}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <h3 className="text-muted" style={{ cursor: "pointer" }}>
                                                    Haematology
                                                </h3>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="blog grid-blog">
                                        <div
                                            className="image-section"
                                            style={{
                                                backgroundImage: `url(${require('../../../assets/images/doctors/infrastrucure_3.jpg')})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                height: "250px", // Adjust as needed
                                            }}
                                        ></div>
                                        <div className="blog-content">
                                            <span
                                                onClick={() => onClickData('infrastrucure_3.jpg', 'Microbiology and Serology')}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <h3 className="text-muted" style={{ cursor: "pointer" }}>
                                                Microbiology and Serology
                                                </h3>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="blog grid-blog">
                                        <div
                                            className="image-section"
                                            style={{
                                                backgroundImage: `url(${require('../../../assets/images/doctors/infrastrucure_4.jpg')})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                height: "250px", // Adjust as needed
                                            }}
                                        ></div>
                                        <div className="blog-content">
                                            <span
                                                onClick={() => onClickData('infrastrucure_4.jpg', 'Clinical Pathology')}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <h3 className="text-muted" style={{ cursor: "pointer" }}>
                                                Clinical Pathology
                                                </h3>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="blog grid-blog">
                                        <div
                                            className="image-section"
                                            style={{
                                                backgroundImage: `url(${require('../../../assets/images/doctors/infrastrucure_5.jpg')})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                height: "250px", // Adjust as needed
                                            }}
                                        ></div>
                                        <div className="blog-content">
                                            <span
                                                onClick={() => onClickData('infrastrucure_5.jpg', 'Cytology')}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <h3 className="text-muted" style={{ cursor: "pointer" }}>
                                                Cytology
                                                </h3>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="blog grid-blog">
                                        <div
                                            className="image-section"
                                            style={{
                                                backgroundImage: `url(${require('../../../assets/images/doctors/infrastrucure_6.jpg')})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                height: "250px", // Adjust as needed
                                            }}
                                        ></div>
                                        <div className="blog-content">
                                            <span
                                                onClick={() => onClickData('infrastrucure_6.jpg', 'Histopathology')}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <h3 className="text-muted" style={{ cursor: "pointer" }}>
                                                Histopathology
                                                </h3>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="blog grid-blog">
                                        <div
                                            className="image-section"
                                            style={{
                                                backgroundImage: `url(${require('../../../assets/images/doctors/infrastrucure_7.jpg')})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                height: "250px", // Adjust as needed
                                            }}
                                        ></div>
                                        <div className="blog-content">
                                            <span
                                                onClick={() => onClickData('infrastrucure_7.jpg', 'Referral Lab')}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <h3 className="text-muted" style={{ cursor: "pointer" }}>
                                                Referral Lab
                                                </h3>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="blog grid-blog">
                                        <div
                                            className="image-section"
                                            style={{
                                                backgroundImage: `url(${require('../../../assets/images/doctors/infrastrucure_8.jpg')})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                height: "250px", // Adjust as needed
                                            }}
                                        ></div>
                                        <div className="blog-content">
                                            <span
                                                onClick={() => onClickData('infrastrucure_8.jpg', 'Advance Imaging')}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <h3 className="text-muted" style={{ cursor: "pointer" }}>
                                                Advance Imaging
                                                </h3>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="blog grid-blog">
                                        <div
                                            className="image-section"
                                            style={{
                                                backgroundImage: `url(${require('../../../assets/images/doctors/infrastrucure_9.jpg')})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                height: "250px", // Adjust as needed
                                            }}
                                        ></div>
                                        <div className="blog-content">
                                            <span
                                                onClick={() => onClickData('infrastrucure_9.jpg', 'Imaging Services')}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <h3 className="text-muted" style={{ cursor: "pointer" }}>
                                                Imaging Services
                                                </h3>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="blog grid-blog">
                                        <div
                                            className="image-section"
                                            style={{
                                                backgroundImage: `url(${require('../../../assets/images/doctors/infrastrucure_10.jpg')})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                height: "250px", // Adjust as needed
                                            }}
                                        ></div>
                                        <div className="blog-content">
                                            <span
                                                onClick={() => onClickData('infrastrucure_10.jpg', 'Physiotherapy')}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <h3 className="text-muted" style={{ cursor: "pointer" }}>
                                                Physiotherapy
                                                </h3>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/* Repeat above block for other cards */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Infrastructure;
