import React, { useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DashboardSidebar from '../sidebar/sidebar.jsx';
import StickyBox from "react-sticky-box";
import { useHistory } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
// import Modal from '@material-ui/core/Modal';
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import  * as ReactBootstrap from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';

import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { get_all_my_address_req,delete_my_address_request } from "../../../../redux-services/actions/action.myaddress/action.function.js";
import AddAddress from "./addAdress/index.jsx";
 import toaster from "../../../../utils/ReactTostify";

// import { createBrowserHistory } from "history";
import {
    IMG01,
    IMG02,
    IMG03,
    IMG04,
    IMG05,
    IMG06,
    IMG07,
    IMG08,
    IMG09,
    IMG10,
  } from "./img";

function MyAddress(props) {
  const body ='Please provide the valuable inputs';
  const title = "Are you sure you want to delete address?"
  const [openModal, setOpenModal] = useState(false);
  const { SearchBar, ClearSearchButton } = Search;
  const [myAddressData, setMyAddressData] = useState([]);
  const [noMyAddressData, setNoMyAddressData] = useState([]);
  const [alert, setAlert] = useState(false);


 const getMyAddressApiCall = async() =>{
    
   await props.get_all_my_address(null,(error,success)=>{
    if(error){
      
      console.log("error",error);
    }    
    if(success.status == 200 ){
      toaster('success', success.message);
      if (success && success?.response.length > 0) {
        setMyAddressData(success?.response);
      }
      if (success && success?.response.length <= 0) {
        console.log("data length empty",success?.message);
        setNoMyAddressData(success?.message);
      }
     }
     });
    

  }
  
  useEffect(() => {
	
     getMyAddressApiCall();

},[]);
console.log("all my address values=====",myAddressData);
  const history = useHistory();
    const nextPath = (path,data) => {
        // history.push(path);
        history.push({
          pathname: path,
          state:data
        });
      }

      const handle_edit =  (data) => {
        console.log("edit  passed ====",data);
     
           nextPath(`/myAddress/MyAddressEdit`,data);
       
        
      };
      const handle_view =  (data) => {
        console.log("view  passed ====",data);
     
           nextPath(`/myAddress/MyAddressView`,data);
         
        
      };

      const handle_delete =  (id) => {
        console.log("delete id passed ====",id);
     
        const getAlert = () => (
          <SweetAlert
        
            showCancel
         
            title={title}
            onConfirm={() => hideAlert(id)}
            onCancel={() =>
            setAlert(null)
            }
            focusCancelBtn
            customButtons={
          <React.Fragment>
            <button  type="button"
                      className="btn btn-primary mr-3" 
                      onClick={() => hideAlert(id)}
                      >Yes</button>
            <button  type="button"
                      className="btn btn-danger"
                      onClick={() => setAlert(null)}
                      >No</button>
          </React.Fragment>
        }
          >
           
          </SweetAlert>
        );
        setAlert(getAlert());
    
         
        
        
      };

      const hideAlert = async (id) => {

        if (id) {
          console.log(`address_id : ${id} `);
          let data = {
            "address_id":id
          }
          console.log(`address_id : ${data.address_id} `);
           await props.delete_my_address(data,(error,success)=>{
            if(error){
              
              console.log("error",error);
            }    
            if(success.status == 200 ){
              toaster('success', success.message);
              getMyAddressApiCall();
             }
             });
       
          // getMyAddressApiCall();
          
        }
        console.log("Hiding alert...");
        // this.setState({
        //   alert: null,
        // });
        setAlert(null);
      };

      const reloadDataModal = async() => {
         await getMyAddressApiCall();
     
       await setOpenModal(!openModal);
     }
   
     const handleModalClick = () => setOpenModal(!openModal);


      // const handleOpen = () => {
      //   setOpenModal(true);
      // };
    
      // const handleClose = () => {
      //   setOpenModal(false);
      // };
      

      const columnsData = [
        { dataField: 'member_name', text: 'Name' },
        { dataField: 'house_number', 
        text: 'Address',
        formatter: (cell, row) => {
          console.log("row values ==", row);
          return (
            <div>
              <div className="">
                {`No ${row.house_number}`}<br/>
                {`${row.street_name} ${row.area}`}<br/>
                {`${row.landmark}`}<br/>
                {`${row.city}`}<br/>
                {`${row.state} - ${row.pincode}`}<br/>
              </div>
            </div>
          );
        }
       },
        // { dataField: 'mobile', 
        //   text: 'Mobile Number',
        //   formatter: (cell, row) => {
        //     // console.log("row values ==", row);
        //     return (
        //       <div>
        //         <div className="">
        //           {`+91 ${row.mobile}`}
        //         </div>
        //       </div>
        //     );
        //   } 
        // },
        {
      dataField: 'link',
      text: '',
      formatter: (rowContent, row) => {
        return (    
          <div className="table-action">
                                      <button type="button" className="btn btn-sm bg-info-light"   onClick={() =>handle_view(row)}>
                                          <i className="far fa-eye"></i> View
                                        </button>
  
                                       <button type="button" className="btn btn-sm bg-warning-light" onClick={() =>handle_edit(row)}> 
                                         <i className="fas fa-edit"></i> Edit
                                      </button>
                                      <button type="button" className="btn btn-sm bg-primary-light" onClick={() =>handle_delete(row.address_id)}>
                                        <i className="fas fa-trash-alt"></i> Delete
                                      </button>
           </div>                           
      )
    }
}
      
    ];
  
    const pagination = paginationFactory({
      page: 1,
      sizePerPage: 5,
      lastPageText: '>>',
      firstPageText: '<<',
      nextPageText: '>',
      prePageText: '<',
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: function (page, sizePerPage) {
        console.log('page', page);
        console.log('sizePerPage', sizePerPage);
      },
      onSizePerPageChange: function (page, sizePerPage) {
        console.log('page', page);
        console.log('sizePerPage', sizePerPage);
      }
    });
    







  
    return (
        <div>
        {alert ? alert : null}
        <div className="breadcrumb-bar">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-md-12 col-12">
							<nav aria-label="breadcrumb" className="page-breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/home">Home</a></li>
									<li className="breadcrumb-item active" aria-current="page">My Address</li>
								</ol>
							</nav>
							<h2 className="breadcrumb-title">My Address</h2>
						</div>
					</div>
				</div>
			</div>
        <div className="content">
        <div className="container-fluid">
       <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <DashboardSidebar {...props}/>
              </StickyBox>
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="card">
              <div className="card-body user-tabs ">
               <div className="row">
               <div className="mr-auto p-1">
                <strong> Address </strong>
                  </div>
                {/* <div className="mr-auto p-1">
                <strong> Family Members </strong>
                  </div> */}
                  <div className="" >
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleModalClick}
                    // onClick={() =>
                    //   nextPath("/Package/AddPackage")
                    // }
                    // onClick={handleModalClick}
                  >
                    <span >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </span>
                    <span>Add Address</span>
                  </button>
                </div>
              </div>
              </div>
                 
                      <div className="card card-table mb-0 mt-2">
                        <div className="card-body">
                          <div className="table-responsive">

                          <ToolkitProvider
                             bootstrap4
                             keyField='address_id'
                             data={myAddressData}
                             columns={columnsData}
                             search
                           > 
       
      {
          props => (
            <div>
              {/* <h6>Input something at below input field:</h6> */}
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable striped bordered hover
                // defaultSorted={defaultSorted}
                pagination={pagination}
                noDataIndication={() =>(<div className="text-alert"><i>{noMyAddressData}</i></div>)}
                {...props.baseProps}
              />
              </div>
          )
        }
      </ToolkitProvider>
                           
                          </div>
                        </div>
                      </div>
                  
                    
                </div> 
              </div>
            </div>
          </div> 
        </div>
        {/* <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="mx-auto"
      >
        {<AddAddress open={openModal} onClose={handleClose}/>}
         </Modal> */}

         <Modal size="lg" isOpen={openModal} toggle={handleModalClick}  className="addFamilyMember"  backdrop="static">
              <ModalHeader toggle={handleModalClick} className="titleName">
              <strong> Add Address </strong>
                </ModalHeader>
                <ModalBody>
                <AddAddress
              {...props}
              toggle={handleModalClick}
              reloadAddress={reloadDataModal}
            />
                </ModalBody>
              </Modal>
      </div>
      </div>
    );

    
}

const mapDispatchToProps = (dispatch) => {
	return {
		//  logout_req: () =>dispatch(logout_req()),
    get_all_my_address: (data,callback) =>dispatch(get_all_my_address_req(data,callback)),
    delete_my_address: (data,callback) =>dispatch(delete_my_address_request(data,callback))
	};
  };
  const mapStateToProps = (state) => {
	return {
	  status: state.user.status,
	  error: state.user.error,
    all_my_address: state.myaddress.all_my_address,
    user_by_id:state.user.user_by_id,
	  user: state.user.user,
	  isLoggedIn: state.user.isLoggedIn,
	  requesting: state.user.requesting,
  
  
	};
  };
  export default connect(mapStateToProps, mapDispatchToProps)(MyAddress);
