import React, {useState,useEffect}  from 'react';
import DatePicker from "react-datepicker";
function SearchFilter(props) {
    // state = {
    //     startDate: new Date()
    //   };
     
    //   handleChange = date => {
    //     this.setState({
    //       startDate: date
    //     });
    //   };  
    const [search, setSearch] = useState(null);
    const [searchCategory, setSearchCategory] = useState(null);

    const searchSpace = (event) => {
      let keyword = event.target.value;
      setSearch(keyword);
      props.searchFilter(keyword);
    };
    const searchCategorySpace = (event) => {
      let keyword = event.target.value;
      setSearchCategory(keyword);
      props.searchCategoryFilter(keyword);
    };

        return(
            <div className="card search-filter">
            {/* <div className="card-header">
              <h4 className="card-title mb-0">Search Filter</h4>
            </div> */}
            <div className="card-bodys">
              {/* <div className="filter-widget">
                                    <div className="cal-icon">
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleChange}
                                        className="form-control datetimepicker"
                                    />
                                    </div>			
                                </div> */}
    
              <div className="card search-widgettest">
                <div className="card-bodys">
                  <form className="search-formtest">
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Search Package Name..."
                        className="form-control"
                        onChange={(e) => searchSpace(e)}
                      />
                      <div className="input-group-append">
                        <button type="submit" className="btn btn-primary">
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="filter-widget">
                <h4>Gender</h4>
                <div>
                  <label className="custom_check">
                    <input type="checkbox" name="gender_type" defaultChecked />
                    <span className="checkmark"></span> Male Doctor
                  </label>
                </div>
                <div>
                  <label className="custom_check">
                    <input type="checkbox" name="gender_type" />
                    <span className="checkmark"></span> Female Doctor
                  </label>
                </div>
              </div> */}
              {/* <div className="filter-widget">
                <h4>Select Category</h4>
                {(props.searchCategoryData.length > 0)? (props.searchCategoryData.map((category,idx) =>(
            <div key={idx}>
            <label className="custom_check "> 
            
                <input
                  type="checkbox"
                  name="select_specialist"

                  className="style"
                  // defaultChecked
                />
                
              <span className="checkmark"></span>  
              
               <div className="style"> 
                {(category.cat_name != null)? category.cat_name:null}  
              </div> 
                 
              </label>
            </div>
           ))):null

           }
              </div> */}
              {/* <div className="btn-search">
                <button type="button" className="btn btn-block">
                  Search
                </button>
              </div> */}
            </div>
          </div>
        );
    
}

export default SearchFilter;