import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import {connect} from "react-redux";
import moment from "moment";
// import {IMG_blog_th01, IMG_blog_th02, IMG_blog_th03, IMG_blog_th04, IMG_blog_th05} from '.././articlelist/img.jsx';
import  IMG_blog_th01  from "../../../../assets/images/placeholderImg.png";
import {get_all_articles_req} from "../../../../redux-services/actions/action.article/action.function.js";
import { API_URL_ROOT } from '../../../../redux-services/actions/constance/action.constance';
import  articon  from "../../../../assets/images/thumbnails.png";
import "./lastest.css"
function LastestArticle(props){
	const [articleData, setArticleData] = useState([]);
	const [noArticleData, setNoArticleData] = useState(null);
  
  
	const nextPathDataPush = (path,data) =>{
	  props.history.push({pathname: path,state: {
	  data: data,
	  }
	})
	}
  
	const getArticleApiCall = async() =>{
	await props.get_all_articles(null,(error,success)=>{
	  if(error){
		
		console.log("error",error);
	  }    
	  if(success.status == 200 ){
		if (success && success?.response.length > 0) {
			 let sortData = success?.response.sort((date1,date2) => moment(date2.created_at).format("X") - moment(date1.created_at).format("X"));
			
			console.log("sort data by date", sortData);
		  setArticleData(sortData);
		}
		if (success && success?.response.length <= 0) {
		  console.log("data length empty",success?.message);
		  setNoArticleData(success?.message);
		}
	   }
	   });
	  
  
	}
	
	useEffect(() => {
	  
	   getArticleApiCall();
  
  },[]);
  const onClickArticle = (articleid) =>{
	  var data = {
  
		   "articleid":articleid,
		   
		 
		}
	 
		nextPathDataPush(`/home/articles/articledetails`,data);
		
		  
  
   }
  console.log("all article values=====",articleData);
   
        return(
            <div className="card post-widget">
								<div className="card-header article-heading">
								
									<h4 className="card-title"><img src={articon} alt="BigCo Inc. logo"/>Latest Articles</h4>
								</div>
								<div className="card-body">
									{/* api call data */}
									<>	
									{(articleData.length != 0)?articleData.map((data, idx) =>(
                                    <div key={idx} className="mb-2">
									<ul className="latest-posts">
									<li onClick={() =>onClickArticle(data.article_id)}  style={{
                      cursor: "pointer",
                    }}>
									{/* <span onClick={() =>onClickArticle(data.article_id)}  style={{
                      cursor: "pointer",
                    }}> */}
											<div className="post-thumb">
											
											<img className="img-fluid" 
											//src={IMG_blog_th01}
											src={(data.featured_image === null || data.featured_image === "null")?IMG_blog_th01:`${API_URL_ROOT}/${data.featured_image}`}
											alt="Post" />
								</div>
										
											<div className="post-info">
												<h4>
												{(data.title != null) ?((data.title.length > 30)?`${data.title.substring(0,30)}...`:data.title) : null}
                                            
												</h4>
												<p>{(data.created_at != null)?moment(data.created_at).format("DD MMM YYYY"):null}</p>
											</div>
											{/* </span> */}
											
										</li>
									
										
									</ul>
									</div>
									)):(
										<div>
										{(noArticleData!= null)?noArticleData:null}
										</div>	

											
									)}
									</>

                                      
	                              
										 


									{/* dummy data  */}
									{/* <span onClick={() =>onClickArticle(data.article_id)}  style={{
                      cursor: "pointer",
                    }}>
                                            <img className="img-fluid" 
                                                // src={IMG02}  
                                                src={(data.featured_image == null)?IMG02:`${API_URL_ROOT}/${data.featured_image}`}
                                                alt="Post" /> */}
									{/* <ul className="latest-posts">
										<li>
											<div className="post-thumb">
												<Link to="/home/articles/articledetails">
													<img className="img-fluid" src={IMG_blog_th01} alt="" />
												</Link>
											</div>
											<div className="post-info">
												<h4>
                                                <Link to="/home/articles/articledetails">Padmashree Diagnostics – Making your clinic painless visit?</Link>
												</h4>
												<p>4 Aug 2021</p>
											</div>
										</li>
										<li>
											<div className="post-thumb">
                                            <Link to="/home/articles/articledetails">
													<img className="img-fluid" src={IMG_blog_th02} alt="" />
                                                    </Link>
											</div>
											<div className="post-info">
												<h4>
                                                <Link to="/home/articles/articledetails">What are the benefits of Online Doctor Booking? </Link>
												</h4>
												<p>3 Aug 2021</p>
											</div>
										</li>
										<li>
											<div className="post-thumb">
                                            <Link to="/home/articles/articledetails">
													<img className="img-fluid" src={IMG_blog_th03} alt="" />
                                            </Link>
											</div>
											<div className="post-info">
												<h4>
                                                <Link to="/home/articles/articledetails">Benefits of consulting with an Online Doctor  </Link>
												</h4>
												<p>3 Aug 2021</p>
											</div>
										</li>
										<li>
											<div className="post-thumb">
                                            <Link to="/home/articles/articledetails">
													<img className="img-fluid" src={IMG_blog_th04} alt="" />
                                                    </Link>
											</div>
											<div className="post-info">
												<h4>
                                                <Link to="/home/articles/articledetails">5 Great reasons to use an Online Doctor </Link>
												</h4>
												<p>2 Aug 2021</p>
											</div>
										</li>
										<li>
											<div className="post-thumb">
                                            <Link to="/home/articles/articledetails">
													<img className="img-fluid" src={IMG_blog_th05} alt="" />
                                                    </Link>
											</div>
											<div className="post-info">
												<h4>
                                                <Link to="/home/articles/articledetails">Online Doctor Appointment Scheduling</Link>
												</h4>
												<p>1 Aug 2021</p>
											</div>
										</li>
									</ul> */}
								</div>
							</div>
						

						
        );
    
}


const mapDispatchToProps = (dispatch) => {
	return {
		get_all_articles: (data, callback) =>
		dispatch(get_all_articles_req(data, callback)),
	 
	};
  };
  const mapStateToProps = (state) => {
	return {
	  status: state.user.status,
	  error: state.user.error,
	  all_family_member: state.familymember.all_family_member,
	  all_my_address: state.myaddress.all_my_address,
	  create_order: state.order.create_order,
	  all_coupons: state.order.all_coupons,
	  user_by_id: state.user.user_by_id,
	  cart: state.cart.cartVal,
	  total_cart_value: state.cart.total_cost,
	  user: state.user.user,
	  isLoggedIn: state.user.isLoggedIn,
	  requesting: state.user.requesting
	};
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(LastestArticle);