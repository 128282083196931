import React from "react";
// import config from 'config';
import { Route, BrowserRouter as Router, Switch ,withRouter} from "react-router-dom";
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';

// Custom api

import CustomRoute from "./client/utils/CustomRoute";


import Header from "./client/components/header.jsx";
import Footer from "./client/components/footer.jsx";
import TopHeader from  './client/components/topheader.jsx';
import LoginContainer from "./client/components/login/login.jsx";
import Register from "./client/components/register/register.jsx";
import ForgotPassword from "./client/components/forgot-mpin";
import Home from "./client/components/home/index";



import Calendar from "./client/components/pages/calender";
import Invoice from "./client/components/pages/invoices/invoices";
import InvoiceView from "./client/components/pages/invoices/view";

import Terms from "./client/components/pages/terms";
import Policy from "./client/components/pages/policy";



// ui  bifercation
// Infrastructure
import Infrastructure from "./client/components/home/infrastructure/index.jsx";
import InfrastructureDetails from "./client/components/home/infrastructure/infrastructuredetails/index.jsx";
// AboutContent 
import AboutContent from "./client/components/home/AboutContent/index.jsx";
// Articles
import ArticleView from "./client/components/home/articles/articleView/index.jsx";
import ArticleDetails from "./client/components/home/articles/articledetails/index.jsx";
import LastestArticle from "./client/components/home/articles/lastestarticle/index.jsx";

// Packages 

import AllPackages from "./client/components/home/allPackagesView/allPackages/index.jsx";
import PackageDetails from "./client/components/home/allPackagesView/packageDetails/index.jsx";

// Faqs
import Faq from "./client/components/home/faq/index.jsx";

// Tests
 import AllTests from "./client/components/home/allTestsView/allTests/index.jsx";
 import TestDetails from "./client/components/home/allTestsView/testDetails/index.jsx";

//  Users Module

import UserDashboard from "./client/components/users/dashboard/index.jsx";
import Dashboard1 from "./client/components/users/dashboard/index.jsx";
import UserProfile from "./client/components/users/dashboard/profile/index.jsx";
import ChangeMpin from "./client/components/users/dashboard/changeMpin/index.jsx";
import FamilyMembers from "./client/components/users/dashboard/familyMembers/index.js";
import FamilyMemberEdit from "./client/components/users/dashboard/familyMembers/familyMembersEdit/index.jsx";
import FamilyMemberView from "./client/components/users/dashboard/familyMembers/familyMemberView/index.jsx";
import MyAddress from "./client/components/users/dashboard/myAddress/index.jsx";
import MyAddressEdit from "./client/components/users/dashboard/myAddress/MyAddressEdit/index.jsx";
import MyAddressView from "./client/components/users/dashboard/myAddress/MyAddressView/index.jsx";
import Orders from "./client/components/users/dashboard/Orders/index.jsx";
import OrderView from "./client/components/users/dashboard/Orders/OrderView/index.jsx";
import Support from "./client/components/users/dashboard/Support/index.jsx";
import SupportView from "./client/components/users/dashboard/Support/SupportView/index.jsx";
import Feedback from "./client/components/users/dashboard/Feedback/index.jsx";
import FeedbackView from "./client/components/users/dashboard/Feedback/feedbackView/index.jsx";
import UserLanding from "./client/components/pages/UserLanding/index.jsx";
import UserBooking from "./client/components/users/bookings/booking";
import UserBookingSuccess from "./client/components/users/bookings/booking-success";
import UserCheckout from "./client/components/users/bookings/checkout";
import AddToCart from "./client/components/users/addToCart/index";
import NotificationsView from "./client/components/users/dashboard/Notifications/NotificationsView.jsx";
import NotificationsViewAll from "./client/components/users/dashboard/Notifications/NotificationsViewAll.jsx";
import Reports from "./client/components/users/dashboard/Reports";

// Error pages
import Page400 from "./client/components/ErrorPages/Page400"
import Page401 from "./client/components/ErrorPages/Page401"
import Page404 from "./client/components/ErrorPages/Page404"
import Page500 from "./client/components/ErrorPages/Page500"
import { Dashboard } from "@material-ui/icons";

const loading = () => (
  <div className="animated fadeIn app flex-row align-items-center">
    <CircularProgress />
  </div>
);

const AppContainer = function (props) {
  if (props) {
    const url = props.location.pathname.split("/")[1];
    console.log("url values == ", url);

    return (
      <Router >
      <React.Suspense fallback={loading()}>
        {((props.user != null) ||(props.user != undefined) || (props.user != ""))?(props.user?.userId != null)?(
          <div>
            {/* { url === "homeslider1" && <Route render={(props)=> <TopHeader {...props}/>} />} */}
            <Route render={(props) => <Header {...props} />} />
           
            <Route path="/home/login" exact component={Dashboard1} />
            {/* <Route path="/home/login" exact component={LoginContainer} /> */}
          
            <Switch>
            {/* Users */}

            <CustomRoute
              protectedPath
              path="(/|/users/dashboard)"
              name="Dashboard"
              render={(props) => <UserDashboard {...props} />}
            />

            <Route path= "(/|/users/dashboard)" exact component={UserDashboard} /> 

            <CustomRoute
              protectedPath
              path="/dashboard/profile"
              name="Profile"
              render={(props) => <UserProfile {...props} />}
            />

            
            	
              {/* <Route path="/dashboard/profile" exact component={UserProfile} /> */}
              <CustomRoute
              protectedPath
              path="/dashboard/change-mpin"
              name="Change-Mpin"
              render={(props) => <ChangeMpin {...props} />}
            />
              {/* <Route path="/dashboard/change-mpin" exact component={ChangeMpin} /> */}

              <CustomRoute
              protectedPath
              path="/dashboard/familyMembers"
              name="Family-Members"
              render={(props) => <FamilyMembers {...props} />}
            />
              
              {/* <Route path="/dashboard/familyMembers" exact component={FamilyMembers} /> */}
              <CustomRoute
              protectedPath
              path="/familyMembers/familyMembersEdit"
              name="Family-Members-Edit"
              render={(props) => <FamilyMemberEdit {...props} />}
            />
              {/* <Route path="/familyMembers/familyMembersEdit/:member_id" exact component={ FamilyMemberEdit} /> */}
              <CustomRoute
              protectedPath
              path="/familyMembers/familyMemberView"
              name="Family-Members-View"
              render={(props) => <FamilyMemberView {...props} />}
            />
              {/* <Route path="/familyMembers/familyMemberView/:member_id" exact component={FamilyMemberView} /> */}
              
              <CustomRoute
              protectedPath
              path="/dashboard/myAddress"
              name="My-Address"
              render={(props) => <MyAddress {...props} />}
            />
              {/* <Route path="/dashboard/myAddress" exact component={MyAddress} /> */}

              <CustomRoute
              protectedPath
              path="/myAddress/MyAddressEdit"
              name="My-Address-Edit"
              render={(props) => <MyAddressEdit {...props} />}
            />

              {/* <Route path="/myAddress/MyAddressEdit/:address_id" exact component={MyAddressEdit} /> */}
              <CustomRoute
              protectedPath
              path="/myAddress/MyAddressView"
              name="My-Address-View"
              render={(props) => <MyAddressView {...props} />}
            />
              {/* <Route path="/myAddress/MyAddressView/:address_id" exact component={MyAddressView} /> */}

              <CustomRoute
              protectedPath
              path="/dashboard/Orders"
              name="Orders"
              render={(props) => <Orders {...props} />}
            />

             <CustomRoute
              protectedPath
              path="/dashboard/Reports"
              name="Reports"
              render={(props) => <Reports {...props} />}
            />

              {/* <Route path="/dashboard/Orders" exact component={Orders} /> */}

              <CustomRoute
              protectedPath
              path="/Orders/OrderView"
              name="Order-View"
              render={(props) => <OrderView {...props} />}
            />
              {/* <Route path="/Orders/OrderView/:order_id" exact component={OrderView} /> */}
              <CustomRoute
              protectedPath
              path="/dashboard/Support"
              name="Support"
              render={(props) => <Support {...props} />}
            />

              {/* <Route path="/dashboard/Support" exact component={Support} /> */}

              <CustomRoute
              protectedPath
              path="/Support/SupportView"
              name="SupportView"
              render={(props) => <SupportView {...props} />}
            />
              {/* <Route path="/Support/SupportView/:support_id" exact component={SupportView} /> */}

              <CustomRoute
              protectedPath
              path="/dashboard/Feedback"
              name="Feedback"
              render={(props) => <Feedback {...props} />}
            />

          <CustomRoute
              protectedPath
              path="/Feedback/FeedbackView"
              name="FeedbackView"
              render={(props) => <FeedbackView {...props} />}
            />

             {/* Notifications */}
             <CustomRoute
              protectedPath
              path="/Notifications/NotificationsView"
              name="NotificationsView"
              render={(props) => <NotificationsView {...props} />}
            />

<CustomRoute
              protectedPath
              path="/Notifications/NotificationsViewAll"
              name="NotificationsViewAll"
              render={(props) => <NotificationsViewAll {...props} />}
            />



<CustomRoute
              protectedPath
              path="/bookings/Userbooking"
              name="UserBookings"
              render={(props) => <UserBooking {...props} />}
            />


<CustomRoute
              protectedPath
              path="/users/addToCart"
              name="AddToCart"
              render={(props) => <AddToCart {...props} />}
            />


<CustomRoute
              protectedPath
              path="/bookings/UserbookingSuccess"
              name="UserBookingSuccess"
              render={(props) => <UserBookingSuccess {...props} />}
            />

<CustomRoute
              protectedPath
              path="/bookings/Usercheckout"
              name="UserCheckout"
              render={(props) => <UserCheckout {...props} />}
            />

           




              {/* <Route path="/dashboard/Feedback" exact component={Feedback} /> */}
              {/* Home */}
            <Route path="/home" exact component={Home} />
            {/* Infrastructure */}
            <Route path="/home/infrastructure" exact component={Infrastructure} />
              <Route path="/home/infrastructure/infrastructuredetails" exact component={InfrastructureDetails} />
              {/* AboutContent */}
              <Route path="/home/AboutContent" exact component={AboutContent} />

              {/* Articles  */}

              <Route path="/home/articles/articleView" exact component={ArticleView} />
              <Route path="/home/articles/articledetails" exact component={ArticleDetails} />
              <Route path="/home/articles/latestarticle" exact component={LastestArticle} />

              {/* Packages */}
              <Route path="/home/allPackagesView/allPackages" exact component={AllPackages} />
              <Route path="/home/allPackagesView/packageDetails" exact component={PackageDetails} />


              {/* Tests */}
              <Route path="/home/allTestsView/allTests" exact component={AllTests} />
              <Route path="/home/allTestsView/testDetails" exact component={TestDetails} />

              {/* Faqs */}
              <Route path="/home/faq" exact component={Faq} />

              {/* privacy policy */}
              <Route path="/pages/terms" exact component={Terms} />
              <Route path="/pages/privacy-policy" exact component={Policy} />
            </Switch>
            <Route render={(props) => <Footer {...props} />} />
            </div>
        ):(
          <div>
            {/* { url === "homeslider1" && <Route render={(props)=> <TopHeader {...props}/>} />} */}
            <Route render={(props) => <Header {...props} />} />
            <Switch>
            <Route path="(/|/home)" exact component={Home} />	
             =

              <Route path="/home/login" exact component={LoginContainer} />
              <Route path="/home/register" exact component={Register} />
              <Route path="/forgot-mpin" exact component={ForgotPassword} />
              {/* Infrastructure */}
              <Route path="/home/infrastructure" exact component={Infrastructure} />
              <Route path="/home/infrastructure/infrastructuredetails" exact component={InfrastructureDetails} />

                 {/* AboutContent */}
              <Route path="/home/AboutContent" exact component={AboutContent} />
              {/* Articles  */}

              <Route path="/home/articles/articleView" exact component={ArticleView} />
              <Route path="/home/articles/articledetails" exact component={ArticleDetails} />
              <Route path="/home/articles/latestarticle" exact component={LastestArticle} />

              {/* Packages */}
              <Route path="/home/allPackagesView/allPackages" exact component={AllPackages} />
              <Route path="/home/allPackagesView/packageDetails" exact component={PackageDetails} />


              {/* Tests */}
              <Route path="/home/allTestsView/allTests" exact component={AllTests} />
              <Route path="/home/allTestsView/testDetails" exact component={TestDetails} />

              {/* Faqs */}
              <Route path="/home/faq" exact component={Faq} />

              {/* Add to cart  */}
              {/* <Route
            
              path="/users/addToCart"
              exact component={AddToCart}
            /> */}

  
              {/* Users */}
              {/* <Route path="/users/dashboard" exact component={UserDashboard} />
              <Route path="/dashboard/profile" exact component={UserProfile} />
              <Route path="/dashboard/change-mpin" exact component={ChangeMpin} />
              <Route path="/dashboard/familyMembers" exact component={FamilyMembers} />
              <Route path="/familyMembers/familyMembersEdit/:member_id" exact component={ FamilyMemberEdit} />
              <Route path="/familyMembers/familyMemberView/:member_id" exact component={FamilyMemberView} />
              
              
              <Route path="/dashboard/myAddress" exact component={MyAddress} />

              <Route path="/myAddress/MyAddressEdit/:address_id" exact component={MyAddressEdit} />
              <Route path="/myAddress/MyAddressView/:address_id" exact component={MyAddressView} />


              <Route path="/dashboard/Orders" exact component={Orders} />
              <Route path="/Orders/OrderView" exact component={OrderView} />

              <Route path="/dashboard/Support" exact component={Support} />
              <Route path="/Support/SupportView" exact component={SupportView} />

              <Route path="/dashboard/Feedback" exact component={Feedback} /> */}






              {/* <Route path="/pages/UserLanding" exact component={UserLanding} /> */}




              {/* Error pages */}
              <Route
              exact path="/401"
              name="Page 401"
              render={(props) => <Page401 {...props} />}
            />

              <Route
              exact path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact path="/400"
              name="Page 400"
              render={(props) => <Page400 {...props} />}
            />
            <Route
              exact path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />



    
              <Route path="/pages/terms" exact component={Terms} />
              <Route path="/pages/privacy-policy" exact component={Policy} />
              
              
            </Switch>
            <Route render={(props) => <Footer {...props} />} />
          </div>
        ):(
          <div>
            {/* { url === "homeslider1" && <Route render={(props)=> <TopHeader {...props}/>} />} */}
            <Route render={(props) => <Header {...props} />} />
            <Switch>
            <Route path="(/|/home)" exact component={Home} />
              <Route path="/home/login" exact component={LoginContainer} />
              
              <Route path="/home/register" exact component={Register} />
              <Route path="/forgot-mpin" exact component={ForgotPassword} />
            	
              

              

              {/* Infrastructure */}
              <Route path="/home/infrastructure" exact component={Infrastructure} />
              <Route path="/home/infrastructure/infrastructuredetails" exact component={InfrastructureDetails} />

               {/* AboutContent */}
               <Route path="/home/AboutContent" exact component={AboutContent} />

              {/* Articles  */}

              <Route path="/home/articles/articleView" exact component={ArticleView} />
              <Route path="/home/articles/articledetails" exact component={ArticleDetails} />
              <Route path="/home/articles/latestarticle" exact component={LastestArticle} />

              {/* Packages */}
              <Route path="/home/allPackagesView/allPackages" exact component={AllPackages} />
              <Route path="/home/allPackagesView/packageDetails" exact component={PackageDetails} />


              {/* Tests */}
              <Route path="/home/allTestsView/allTests" exact component={AllTests} />
              <Route path="/home/allTestsView/testDetails" exact component={TestDetails} />

              {/* Faqs */}
              <Route path="/home/faq" exact component={Faq} />
  
             {/* Add to cart  */}
             {/* <Route
            
            path="/users/addToCart"
            exact component={AddToCart}
          /> */}


              {/* Error pages */}

              <Route
              exact path="/401"
              name="Page 401"
              render={(props) => <Page401 {...props} />}
            />

              <Route
              exact path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact path="/400"
              name="Page 400"
              render={(props) => <Page400 {...props} />}
            />
            <Route
              exact path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />

              {/* pages */}   
              <Route path="/pages/terms" exact component={Terms} />
              <Route path="/pages/privacy-policy" exact component={Policy} />
              
              
            </Switch>
            <Route render={(props) => <Footer {...props} />} />
          </div>
        )}
        </React.Suspense>
      </Router>
    );
  }
  return null;
};

const mapDispatchToProps = (dispatch) => {
	return {
		// login_req: (data) =>dispatch(login_req(data)),
	};
  };
  const mapStateToProps = (state) => {
	return {
	  status: state.user.status,
	  error: state.user.error,
	  user: state.user.user,
	  isLoggedIn: state.user.isLoggedIn,
	  requesting: state.user.requesting,
  
  
	};
  };
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));

// export default withRouter(AppContainer);
