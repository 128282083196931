import React, { Component } from "react";
import Slider from "react-slick";
import testimonial1 from "../../../assets/images/testimonials.jpg";
import testimonial2 from "../../../assets/images/testimonial2.jpg";
import "./testimonials.css"
class Counts extends Component {
  render() {
    const settings = {
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true, // Enable autoplay
      autoplaySpeed: 3000, // Set autoplay interval (in milliseconds)
      responsive: [
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 993,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
      ],
    };

    return (
        <section className="testimonial">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12">
                  
                    <div className="testimonial-slider slider">
                    <Slider {...settings}>
                     
                        <div className="testimonial-item">
                            <div className="row testimonial-row">
                                <div className="col-12 col-lg-9">
                                    <div className="left">
                                        <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2>
                                        <p>"Appreciate the staff for their quick response. Well maintained Diagnostics!!".</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3">
                                    <div className="testimonial-profile text-center">
                                        <div>
                                            <div className="profile-icon">
                                                <img src={require('../../../assets/images/testimonial2.jpg')} alt="" />
                                            </div>
                                            <h4>Bakthavatsalam</h4>
                                            <span>Our clients</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>	
                       
                        <div className="testimonial-item">
                            <div className="row">
                                <div className="col-12 col-lg-9">
                                    <div className="left">
                                        <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2>
                                        <p>“Communication good , hospitality excellent, punctuality excellent. cleanliness was so good..”</p>
                        
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3">
                                    <div className="testimonial-profile text-center">
                                        <div>
                                            <div className="profile-icon">
                                                <img src={require('../../../assets/images/testimonials.jpg')} alt="" />
                                            </div>
                                            <h4>Rekha</h4>
                                            <span>Our Clients</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>						
                      
                        <div className="testimonial-item">
                            <div className="row">
                                <div className="col-12 col-lg-9">
                                    <div className="left">
                                        <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2>
                                        <p>“VERY HAPPY WITH THE FACILITY AND SERVICE PROVIDED”.</p>
                                       
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3">
                                    <div className="testimonial-profile text-center">
                                        <div>
                                            <div className="profile-icon">
                                                <img src={require('../../../assets/images/testimonial2.jpg')} alt="" />
                                            </div>
                                            <h4>Mallikarjuna</h4>
                                            <span>Our Clients</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>	
                        <div className="testimonial-item">
                            <div className="row">
                                <div className="col-12 col-lg-9">
                                    <div className="left">
                                        <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2>
                                        <p>“I am regularly visit all the health care required for all my family members. i am very much satisfied with the service and their gesture”.</p>
                                
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3">
                                    <div className="testimonial-profile text-center">
                                        <div>
                                            <div className="profile-icon">
                                                <img src={require('../../../assets/images/testimonials.jpg')} alt="" />
                                            </div>
                                            <h4>Tejaswi D K</h4>
                                            <span>Clients</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>		
                        <div className="testimonial-item">
                            <div className="row">
                                <div className="col-12 col-lg-9">
                                    <div className="left">
                                        <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2>
                                        <p>“Appreciate the staff for their quick response. Well maintained Diagnostics!!”.</p>
                                       
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3">
                                    <div className="testimonial-profile text-center">
                                        <div>
                                            <div className="profile-icon">
                                                <img src={require('../../../assets/images/testimonial2.jpg')} alt="" />
                                            </div>
                                            <h4>Shivakumar sidd</h4>
                                            <span>Clients</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>		
                        <div className="testimonial-item">
                            <div className="row">
                                <div className="col-12 col-lg-9">
                                    <div className="left">
                                        <h6>Our Clients Say</h6>
                                        <h2>Testimonials</h2>
                                        <p>Kudos team Padmashree Diagnostics, I’m impressed with the professionalism shown by you entire team and I am amazed how hasselfree the entire check up procedure can be. Would always recommend my circle to you guys.</p>
                                       
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3">
                                    <div className="testimonial-profile text-center">
                                        <div>
                                            <div className="profile-icon">
                                                <img src={require('../../../assets/images/testimonials.jpg')} alt="" />
                                            </div>
                                            <h4>Apoorv parihar</h4>
                                            <span>Clients</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>							
                        </Slider>
                        
                    </div>
                  
                    
                </div>
            </div>
        </div>   
    </section>	
    );
  }
}

export default Counts;
