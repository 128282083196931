import React from 'react';
// import './AboutContent.css';
import { Link } from 'react-router-dom';
import "./about.css"



const AboutContent = () => {
  
  const directors = [
    {
      name: "Mrs. Shruthi Ashwath",
      role: "Managing Director",
      image: require('../../../assets/images/doctors/homedirector.jpg'),
      quote: (
        <ul>
          <li className='text-justify'>At Padmashree, our passion to the well-being of the community we live in, fuels our day-to-day work. Each one of us are driven to help community members, our largest stakeholders to be their personal best and lead a healthier life.</li>
          <li className='text-justify'>In almost two decades of our existence, we have set a new benchmark for excellence in preventive healthcare. We understand the importance of a diagnostic center in timely detection and diagnosis of health ailments, thus have partnered with leading hospitals and consultants in the city to offer our people superior service.</li>
          <li className='text-justify'>We believe that access to best healthcare services is a universal right and no citizen be denied of it. It has been our constant endeavor to offer a diagnostic center with state-of-the-art infrastructure to meet the needs of the poor and lower income group of the society. Our patient-centric approach to healthcare has won us constant support, appreciation of our customers and that of the medical fraternity in the country.</li>
        </ul>
      )
    },
    {
      name: "Prof. R. Rajesh Shenoy",
      role: "Lab Director", 
      image: require('../../../assets/images/doctors/Rajesh-Shenoy (1).jpg'),
      quote: (
        <ul>
          <li className='text-justify'>"Padmashree Diagnostics" a modern, state of the art facility in heart of Vijayanagar.</li>
          <li className='text-justify'>We have been serving the people from 19 years with a vision to help and serve its community, in a better way.</li>
          <li className='text-justify'>While Padmashree has retained its mainstay of unbiased service to all, it has also made a concentrated effort to better its services by adding on new facilities and upgrading the existing ones.</li>
        </ul>
      )
    }
  ];

  const whyChooseUs = [
    {
      title: "Advanced Technology",
      description: "Modern technologies and well-trained staff ensure quick and reliable test results.",
      icon: "🔬"
    },
    {
      title: "Community Focused",
      description: "Committed to providing affordable healthcare to all members of our community.",
      icon: "❤️"
    },
    {
      title: "NABL Accredited",
      description: "Meeting the highest quality standards set by the National Accreditation Board.",
      icon: "🏆"
    }
  ];

  return (
    <div className="about-page">
    
      {/* <div className="breadcrumb-section">
        <div className="container">
          <nav className="breadcrumb">
            <Link to="/home" className="breadcrumb-link">Home</Link>
            <span>/</span>
            <span className="breadcrumb-current">About Us</span>
          </nav>
          <h1 className="page-title">About Padmashree Diagnostics</h1>
        </div>
      </div> */}
      <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img className="d-block w-100" src="https://via.placeholder.com/1920x500?text=First+Slide" alt="First slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src="https://via.placeholder.com/1920x500?text=Second+Slide" alt="Second slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src="https://via.placeholder.com/1920x500?text=Third+Slide" alt="Third slide" />
          </div>
        </div>
        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>

      
    
      <section className="company-profile">
        <div className="container">
          <div className="profile-grid">
            <div className="profile-description">
              <h2 className='text-center'>Company profile</h2>
              <p className='text-justify'>
              Offering complete set of laboratory and imaging services, Padmashree Diagnostics is one of the leading diagnostic centers in Bengaluru. Launched in the year 2000, we are a trusted diagnostics partner to all leading hospitals and independent doctors in Bengaluru. Our founder, Dr. C. N Ashwathnarayan, believed in providing preventive healthcare and state of the art Diagnostic facilities for Diagnosis and treatment at affordable price is the key to help millions of people to lead better and healthier lives. Named after his mother Smt. A.L. Padmamma, an inspiration for him to study medicine. Padmashree Diagnostics brings the best of the services at prices that a common man can afford to.
              </p>
            </div>
            <div className="profile-highlights">
              <div className="highlight-card">
                <span className="highlight-icon">🏥</span>
                <h3>Comprehensive Services</h3>
              </div>
              <div className="highlight-card">
                <span className="highlight-icon">🏥</span>
                <h3>Comprehensive Services</h3>
              </div>
              <div className="highlight-card">
                <span className="highlight-icon">🏥</span>
                <h3>Comprehensive Services</h3>
              </div>
              <div className="highlight-card">
                <span className="highlight-icon">🏅</span>
                <h3>NABL Accredited</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="leadership-section">
  <div className="container">
    <h2 className="section-title">Leadership</h2>
    <div className="directors-grid">
      {directors.map((director, index) => (
        <div 
          key={index} 
          className={`director-card ${index % 2 === 0 ? 'image-right' : 'image-left'}`}
        >
          {index % 2 === 0 ? (
            <>
              <div className="director-details">
                <h3>{director.name}</h3>
                <p className="director-role">{director.role}</p>
                <p className="director-quote">{director.quote}</p>
              </div>
              <div className="director-image-container">
                <img 
                  src={director.image} 
                  alt={director.name} 
                  className="director-image"
                />
              </div>
            </>
          ) : (
            <>
              <div className="director-image-container">
                <img 
                  src={director.image} 
                  alt={director.name} 
                  className="director-image"
                />
              </div>
              <div className="director-details">
                <h3>{director.name}</h3>
                <p className="director-role">{director.role}</p>
                <p className="director-quote text-justify">{director.quote}</p>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  </div>
</section>


      <section className="why-choose-us">
        <div className="container">
          <h2 className="section-title">Why Choose Padmashree</h2>
          <div className="features-grid">
            {whyChooseUs.map((feature, index) => (
              <div key={index} className="feature-card">
                <span className="feature-icon">{feature.icon}</span>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="nabl-certification-detailed">
        <div className="container">
          <div className="nabl-content-wrapper">
            <div className="nabl-image-container">
              <img 
                src={require('../../../assets/images/doctors/home-certificate.jpg')} 
                alt="NABL Accreditation Certificate" 
                className="nabl-certificate-image"
              />
            </div>
            <div className="nabl-details">
              <h2>NABL Accreditation: Our Commitment to Quality</h2>
              <div className="nabl-info-sections">
                <div className="nabl-info-section">
                  <h3>What is NABL?</h3>
                  <p className='text-justify'>
                    NABL (National Accreditation Board for Testing and Calibration Laboratories) 
                    is a premier organization that provides accreditation to testing and calibration 
                    laboratories in India, operating under the aegis of Quality Council of India.
                  </p>
                </div>

                <div className="nabl-info-section">
                  <h3>International Recognition</h3>
                  <p className='text-justify'>
                    Our NABL accreditation is recognized internationally through agreements with 
                    ILAC (International Laboratory Accreditation Cooperation) and APLAC 
                    (Asia Pacific Laboratory Accreditation Cooperation). This ensures our test 
                    results are accepted worldwide.
                  </p>
                </div>

                <div className="nabl-info-section">
                  <h3>ISO 15189:2012 Compliance</h3>
                  <p className='text-justify'>
                    We strictly adhere to ISO 15189:2012, the international standard specifically 
                    designed for medical laboratories. This standard ensures:
                    <ul>
                      <li>Technical competence of medical laboratories</li>
                      <li>Consistent quality of diagnostic testing</li>
                      <li>Reliable and accurate test results</li>
                      <li>Patient safety and care</li>
                    </ul>
                  </p>
                </div>

                <div className="nabl-info-section">
                  <h3>Our Continuous Commitment</h3>
                  <p className='text-justify'>
                    NABL accreditation is not a one-time achievement. We undergo regular 
                    assessments and audits to maintain our accreditation, ensuring we 
                    continuously meet the highest standards of quality and technical competence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutContent;