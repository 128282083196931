import React, { Component } from 'react';
import Feature1  from '../../../assets/images/feature1.png';
import Feature2  from '../../../assets/images/feature2.png';
import Feature3  from '../../../assets/images/feature3.png';
import Feature4  from '../../../assets/images/feature4.png';
import Feature5  from '../../../assets/images/feature5.png';

class Connectus extends Component{
    
    render(){
       
        return(
          <section className="feature-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="left">
                  <h6>Connecting with us</h6>
                  <h2>We Are Providing A Very Good Care and Safety!</h2>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="right">
                  <p className='text-justify'>We fully realize that quality and accurate diagnosis is an important part of a patient’s healthcare journey and therefore customer centricity is one of our utmost priority. Right from offering a comfortable phlebotomy experience and ensuring the highest quality and accuracy of reports, our teams are aligned to always walk the extra mile for patients. Our best in class information, technology  and security systems aids us to offer the best turn-around times and a seamless experience for all our patients and customers.</p>
                  {/* <a href="#" className="btn-yellow">Make an Appointment</a> */}
                </div>
              </div>
            </div>
            <div className="row feature-column ">
              <div className="feature-box ">
                <div className="inner-feature-box text-center">
                  <div className="feature-icon">
                    <img src={Feature1} alt="" />
                  </div>
                  <h4>Review Your <br />Medical Records</h4>
                  <p>Explains all detail about the patient's history,  diagnostic test results.</p>
                  <div className="feature-btn">
                    <a href="#"><i className="fas fa-chevron-right"></i></a>
                  </div>
                </div>
              </div>
              <div className="feature-box ">
                <div className="inner-feature-box text-center">
                  <div className="feature-icon">
                    <img src={Feature2} alt="" />
                  </div>
                  <h4>Monthly Checkups <br />Available</h4>
                  <p>We also provide health packages like, full body checkup, health checkup etc. View the entire list of lab tests available.</p>             
                  <div className="feature-btn">
                    <a href="#"><i className="fas fa-chevron-right"></i></a>
                  </div>
                </div>
              </div>
              <div className="feature-box">
                <div className="inner-feature-box text-center">
                  <div className="feature-icon">
                    <img src={Feature3} alt="" />
                  </div>
                  <h4>Check Blood<br />Pressures</h4>
                  <p>Safe and Secure  Sample Collection with 100% accurate online reports.</p>
                  <div className="feature-btn">
                    <a href="#"><i className="fas fa-chevron-right"></i></a>
                  </div>
                </div>
              </div>
              <div className="feature-box">
                <div className="inner-feature-box text-center">
                  <div className="feature-icon">
                    <img src={Feature4} alt="" />
                  </div>
                  <h4>Well Qualified<br />Doctors</h4>
                  <p>We have a team of highly qualified doctors and well trained and committed support staff.</p>
                  <div className="feature-btn">
                    <a href="#"><i className="fas fa-chevron-right"></i></a>
                  </div>
                </div>
              </div>
              <div className="feature-box">
                <div className="inner-feature-box text-center">
                  <div className="feature-icon">
                    <img src={Feature5} alt="" />
                  </div>
                  <h4>24/7 Emergency<br />Helps</h4>
                  <p>Diagnostics 24|7 helps you get treated from  certified doctors at any time.</p>
                  <div className="feature-btn">
                    <a href="#"><i className="fas fa-chevron-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        );
    }
}
export default Connectus;