import React from 'react';
import './whybookwithus.css'; // Import the updated CSS

const WhyBookWithUs = () => {
  const features = [
    {
      title: "Book Online",
      description: "Easy online booking system",
      icon: () => (
        <div className="icon-container">
          <div className="icon-bg-purple"></div>
          <div className="icon-bg-gray"></div>
          <div className="icon-dot"></div>
        </div>
      ),
    },
    {
      title: "Home Sample Collection",
      description: "Convenient doorstep collection",
      icon: () => (
        <div className="icon-container">
          <div className="icon-bg-gray"></div>
          <div className="icon-small-dot"></div>
          <div className="icon-small-box"></div>
        </div>
      ),
    },
    {
      title: "Accurate & Quick Reports",
      description: "Fast and reliable results",
      icon: () => (
        <div className="icon-container">
          <div className="icon-bg-orange"></div>
          <div className="icon-bg-purple-light"></div>
        </div>
      ),
    },
  ];

  return (
    <div className="why-book-with-us">
      <h1 className="heading">Why Book with Us?</h1>

      <div className="features-container">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="icon-wrapper">{feature.icon()}</div>
            <h3 className="feature-title">{feature.title}</h3>
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyBookWithUs;
