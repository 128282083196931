// BannerSlider.jsx
import React, { useState, useEffect } from 'react';
import './banner.css';

const bannerData = [
  {
    title: "GET HEALTHIER TOGETHER",
    subtitle: "Book 2 Packages and get Flat 35% OFF on the second package",
    note: "Valid on Essential Packages only",
    buttonText: "BOOK NOW",
    image: "/api/placeholder/500/300"
  },
  {
    title: "FAMILY WELLNESS MONTH",
    subtitle: "Special health checkups for the whole family",
    note: "Limited time offer",
    buttonText: "LEARN MORE",
    image: "/api/placeholder/500/300"
  },
  {
    title: "PREVENTIVE HEALTH CHECKS",
    subtitle: "Early detection for better protection - 25% OFF",
    note: "Available at all centers",
    buttonText: "CHECK NOW",
    image: "/api/placeholder/500/300"
  }
];

const BannerSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % bannerData.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % bannerData.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + bannerData.length) % bannerData.length);
  };

  return (
    <div className="banner-container">
      <div className="banner-slider">
        {bannerData.map((banner, index) => (
          <div
            key={index}
            className={`banner-slide ${currentSlide === index ? 'active' : ''}`}
          >
            <div className="banner-content">
              <div className="banner-text">
                <h2>{banner.title}</h2>
                <p className="subtitle">{banner.subtitle}</p>
                <p className="note">{banner.note}</p>
                <button className="book-button">{banner.buttonText}</button>
              </div>
              <div className="banner-image">
                <img src={banner.image} alt="Promotional" />
              </div>
            </div>
          </div>
        ))}

        {/* <button className="nav-button prev" onClick={prevSlide}>&#8249;</button>
        <button className="nav-button next" onClick={nextSlide}>&#8250;</button> */}

        <div className="dots-container">
          {bannerData.map((_, index) => (
            <button
              key={index}
              className={`dot ${currentSlide === index ? 'active' : ''}`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BannerSlider;