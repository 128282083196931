import React, { useState } from 'react';
import './icons.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { byPrefixAndName } from '@awesome.me/kit-KIT_CODE/icons'
// import { fawhatsapp } from '@awesome.me/kit-KIT_CODE/icons/classic/solid'



const SocialIcons = () => {
  const [isPhoneExpanded, setIsPhoneExpanded] = useState(false);
  const [isWhatsAppExpanded, setIsWhatsAppExpanded] = useState(false);

  const handlePhoneClick = () => {
    setIsPhoneExpanded(!isPhoneExpanded);
    if (isWhatsAppExpanded) setIsWhatsAppExpanded(false);  // Close WhatsApp if Phone is clicked
  };

  const handleWhatsAppClick = () => {
    setIsWhatsAppExpanded(!isWhatsAppExpanded);
    if (isPhoneExpanded) setIsPhoneExpanded(false);  // Close Phone if WhatsApp is clicked
  };

  return (
    <div className="social-icons">
      {/* Phone Button */}
      {/* <button
        className={`expandable-button phone ${isPhoneExpanded ? 'expanded' : ''}`}
        onClick={handlePhoneClick}
        aria-label="Contact Phone"
      >
        <span className="icon">📞</span>
        <div className={`contact-text ${isPhoneExpanded ? 'expanded' : ''}`}>
          <span>Contact Us</span>
          <span>{isPhoneExpanded ? '+1 234 567 890' : ''}</span>
        </div>
      </button> */}

      {/* WhatsApp Button */}
      <button
        className={`expandable-button whatsapp ${isWhatsAppExpanded ? 'expanded' : ''}`}
        onClick={handleWhatsAppClick}
        aria-label="Contact WhatsApp"
      >
        <span className="icon"><i className="fas fa-calendar-check"></i></span>
        <div className={`contact-text ${isWhatsAppExpanded ? 'expanded' : ''}`}>
          <span>Book On Call</span>
          <span>{isWhatsAppExpanded ? '+1 987 654 321' : ''}</span>
        </div>
      </button>
    </div>
  );
};

export default SocialIcons;

